import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useAuthUser } from "react-auth-kit";
import Slider from "react-slick";

import { Stack, Typography, Rating } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

import {
  Container,
  Row,
  Col,
  InputMask,
  Button,
  Avatar,
} from "../../../components";

import { useApi } from "../../../hooks/useApi";
import { API_URL } from "../../../config/apiUrl";
import MenuVisitante from "./MenuVisitante";
import { ModalPintor } from "../../../components/Visitante/components/ModalPintor";

const VisitantePintor = () => {
	const ucWords = (str) => {
		return (str + "").toLowerCase().replace(/^([a-z])|\s+([a-z])/g, function ($1) {
			return $1.toUpperCase();
		});
	}
  const [open, setOpen] = useState(false);
  const [pintor, setPintor] = useState(null);

  const auth = useAuthUser();

  const { cep } = auth();

  const onPintor = (item) => {
    setPintor(item);
    setOpen(true);
  };

  const onClosePintor = () => {
    setOpen(false);
    setPintor(null);
  };

  const [{ data = { pintores: [] }, loading }, fetch] = useApi({
    ...API_URL.PINTORES_CEP,
    params: { cep: cep },
  });

  const onSubmit = (e) => fetch({ params: { cep: e.cep } });

  return (
    <Container loading={loading}>
      <MenuVisitante>
        <Row
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={styles.box}
        >
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <Typography
                  variant="h2"
                  color="primary"
                  fontWeight={700}
                  fontStyle={"italic"}
                >
                  Ache seu pintor!
                </Typography>{" "}
                <Typography
                  color="secondary"
                  fontWeight={700}
                  fontStyle={"italic"}
                  mt={"0.6rem"}
                >
                  Digite seu CEP e confira todos os pintores próximos a você!
                </Typography>{" "}
              </Col>
              <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                  onSubmit(values);
                }}
              >
                {() => (
                  <Form style={{ width: "100%" }}>
                    <Stack spacing={1} width="100%" mt={2}>
                      <Field
                        as={InputMask}
                        mask="99999-999"
                        name="cep"
                        placeholder="CEP"
                        color="cinza"
                      />
                      <Button color="primary" type="submit">
                        <SearchOutlined />
                        BUSCAR
                      </Button>{" "}
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Row>
          </Col>
        </Row>
      </MenuVisitante>{" "}
      <Row spacing={2} mt={1}>
        <Col xs={12}>
          <Row spacing={2} sx={styles.box}>
            <Col xs={12} sm={4}>
              <Typography
                variant="h4"
                color="secondary"
                fontWeight={700}
                fontStyle={"italic"}
              >
                Encontramos estes profissionais na sua região:
              </Typography>
              <Typography variant="body1" fontStyle={"italic"} mt={1}>
                Perfis dos pintores com nome, estrelas, fotos e vídeos.
              </Typography>
            </Col>
            <Col xs={12} sm={8}>
              <Slider
                {...{
                  infinite: true,
                  speed: 500,
                  slidesToShow:
                    data.pintores.length < 3 ? data.pintores.length : 3,
                  slidesToScroll: 1,
                  autoplay: true,
                  arrows: false,
                }}
              >
                {data.pintores.map((item) => (
                  <Stack>
                    <Stack
                      key={item.id}
                      sx={styles.boxPintor}
                      alignItems={"center"}
                      justifyContent="space-around"
                      onClick={onPintor.bind(this, item)}
                    >
                      <Stack>
                        {item.avatar ? (
                          <Avatar
                            src={item.avatar}
                            alt={item.nome}
                            sx={{ width: 90, height: 90 }}
                          />
                        ) : (
                          <Stack sx={styles.avatar} />
                        )}
                      </Stack>

                      <Stack alignItems={"center"} px={0.5}>
                        <Rating
                          name="read-only"
                          value={item.notaMedia}
                          readOnly
                        />

	                      <div style={{textAlign: 'center'}}>
		                      <Typography
			                      variant="body1"
			                      mt={1}
			                      fontWeight={700}
			                      fontSize={"14px"}
			                      fontStyle="italic"
		                      >
			                      {ucWords(item.nome.length > 15 ? (item.nome.substr(0, 15) + '...') : item.nome)}
		                      </Typography>
	                      </div>{" "}
                        <Typography variant="body1" fontSize={"14px"}>
                          {item.cidade} - {item.estado}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
              </Slider>
            </Col>
          </Row>
        </Col>
        <Col xs={12} mt={4}>
          <Row spacing={2}>
            <Col xs={12} md={4}>
              <Typography
                variant="h3"
                fontStyle="italic"
                fontWeight={700}
                textAlign={"center"}
                mr={5}
              >
                HISTÓRICO DE <br /> CONTATOS
              </Typography>{" "}
            </Col>
            <Col xs={12} md={8} sx={styles.box} spacing={2} mt={1}>
              <Typography
                variant="h4"
                color="secondary"
                fontWeight={700}
                fontStyle="italic"
              >
                Acompanhe os profissionais que você entrou em contato e suas
                avaliações.
              </Typography>
              <Typography mt={1} variant="body2" fontStyle="italic">
                <strong>ATENÇÂO:</strong> as suas avaliações são super
                impotantes para que os pintores possam alcançar 5 estrelas,
                pintando um universo de novas oportunidades. Não se esqueça de
                avaliar sempre que um seviço for concluido!
              </Typography>
            </Col>
          </Row>
        </Col>
        <Col xs={12} mt={2}>
          <Row spacing={2} alignItems="center">
            <Col xs={12} md={4}>
              <Typography
                variant="h3"
                fontStyle="italic"
                fontWeight={700}
                textAlign={"center"}
                mr={5}
              >
                DÚVIDAS <br /> FREQUENTES
              </Typography>
            </Col>
            <Col xs={12} md={8} sx={styles.box} spacing={2} mt={1}>
              <Typography
                variant="h5"
                color="secondary"
                fontWeight={700}
                fontStyle="italic"
              >
                Pintou uma dúvida?
              </Typography>
              <Typography variant="body1" fontStyle="italic">
                <strong>Não se preocupe:</strong> estamos aqui para ajudar você!
              </Typography>
              <Typography
                variant="h5"
                color="secondary"
                fontWeight={700}
                fontStyle="italic"
                mt={2}
              >
                Como posso me tornar um pintor 5 estrelas?
              </Typography>
              <Typography variant="body1" fontStyle="italic">
                Para ter 5 estrelas, é necessario que seus clientes deixem pelo
                menos 10 comentários com a classificação "satisfeito"
              </Typography>
              <Typography
                variant="h5"
                color="secondary"
                fontWeight={700}
                fontStyle="italic"
                mt={2}
              >
                Porque um pintor receber 5 estrelas?{" "}
              </Typography>
              <Typography variant="body1" fontStyle="italic">
                Além de ser um exemplo para seus colegas e considerado para mais
                trabalhos, o pintor 5 estrelas ainda ganha 500 pontos no Clube
                PRO Pintor para trocar pelo que quiser.
              </Typography>{" "}
              <Typography
                variant="h5"
                color="secondary"
                fontWeight={700}
                fontStyle="italic"
                mt={2}
              >
                O que acontece se um pintor recebe muitas avaliações negativas?
              </Typography>
              <Typography variant="body1" fontStyle="italic">
                Prezamos por lebar sempre o melhor para nossos clientes e, por
                isso, bloquearemos o cadastro de pintores com mais de 5
                avaliações negativas. Faça seu comentário honestamente!
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
      {open && (
        <ModalPintor open={open} onClose={onClosePintor} pintor={pintor} />
      )}
    </Container>
  );
};

const styles = {
  box: {
    background: "#fff",
    borderRadius: "22px",
    padding: "1rem",
    minHeight: "100%",
  },
  boxPintor: {
    marginLeft: "10px",
    height: "220px",
    maxWidth: "150px",
    border: "double 2px transparent",
    borderRadius: "20px",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    cursor: "pointer",
  },
  boxCadastro: {
    background: "#f2f2f2",
    marginTop: "2rem",
    padding: "2rem",
    borderRadius: "30px",
  },
  boxImg: {
    width: "70px",
    height: "70px",
    border: "double 2px transparent",
    borderRadius: "100%",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
  },
  searchBox: {
    border: "double 3px transparent",
    borderRadius: "100%",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
  },
  avatar: {
    height: "90px",
    width: "90px",
    borderRadius: "100%",
    border: "solid 2px ",
    borderColor: "primary.main",
  },
};

export default VisitantePintor;
