import { Typography } from "@mui/material";
import React from "react";

import { Container } from "../../components";

const TermoVisitante = () => (
  <Container sx={{ py: 10 }}>
    <>
      <Typography sx={{ mt: 6 }} variant="h1" textAlign={"center"}>
        <span size={3}>
          <strong>Os Termos e Condições de Uso&nbsp;</strong>
        </span>
      </Typography>
      <p>
        <strong>
          Plataforma Ache o seu Pintor - Promitentes Contratantes de Serviços de
          Pintura (“Contratante”)
        </strong>
      </p>
      <p>Atualizado em 2 de junho de 2022</p>
      <p>
        <span size={3}>
          A Plataforma SW Indica é uma Plataforma on-line disponibilizada
          através de subdomínios de internet de licenciados à SHERWIN-WILLIAMS
          DO BRASIL INDUSTRIA E COMERCIO LTDA., empresa cadastrada no CNPJ sob
          nº 60.872.306/0001-60 com sede no município de Taboão de Serra,
          capital do Estado de São Paulo, a Av. Ibirama, nº 480, juntamente com
          suas controladoras, subsidiárias, afiliadas e marcas (coletivamente,
          "Empresa”) disponível pelo endereço eletrônico&nbsp;
        </span>
        Clique ou toque aqui para inserir o texto<span size={3}>&nbsp;</span>
        (“Portal”) cujo propósito é prover informações a fim de viabilizar o
        contato direto entre promitentes prestadores de serviços de pintura
        (“Pintores”) e promitentes contratantes (“Contratantes”) interessados em
        contratar os Serviços de Pintura (“Serviços”), por meio da divulgação de
        informações de uma parte à outra (“Plataforma”).
      </p>
      <p>
        <span size={3}>
          Assim, apresentamos os nossos Termos e Condições de Uso da Plataforma
          SW Indica&nbsp;
        </span>
        <span size={3}>
          <u>direcionada aos Contratantes</u>
        </span>
        <span size={3}>
          &nbsp;(“Termos de Uso para Contratantes”), que também inclui nossa
          Política de Privacidade, para que os nossos Usuários compreendam
          facilmente as condições para uso da Plataforma.&nbsp;
        </span>
      </p>
      <p>
        <span size={3}>
          Caso você não esteja interessado em contratar os Serviços e na verdade
          seja um Pintor, por favor, acesse e leia com atenção os Termos e
          Condições de Uso da Plataforma SW Indica direcionada aos Pintores
          disponível aqui.&nbsp;
        </span>
      </p>
      <p>
        A aceitação deste Termos de Uso é absolutamente indispensável à
        navegação e acesso à Plataforma.
      </p>
      <p>
        Antes de utilizar a Plataforma, por favor, leia com bastante atenção
        este documento.
      </p>
      <p>
        CASO VOCÊ TENHA QUALQUER DÚVIDA SOBRE OS TERMOS DE USO, entre em contato
        conosco através dos nossos Canais de Contato.
      </p>
      <p>ASPECTOS GERAIS</p>

      <ol>
        <li value={1}>
          <p>
            <u>
              <strong>Informação</strong>
            </u>
          </p>
          <ol>
            <li>
              <p>
                Este Termos de Uso rege a disponibilização pela Empresa da
                Plataforma on-line denominada SW Indica, através do uso gratuito
                da Plataforma.&nbsp;
              </p>
            </li>
            <li>
              <p>
                O termo “Usuários” referem-se aos (i) Contratantes, pessoas
                físicas e jurídicas, que possuem interesse e capacidade em
                contratar os Serviços, conforme definido nos Termos de Uso para
                Contratantes; e (ii) Pintores, pessoas físicas e jurídicas,
                devidamente habilitadas conforme definido neste Termos de Uso
                para Pintores, que possuem interesse em divulgar e prestar os
                Serviços aos Contratantes.&nbsp;
              </p>
            </li>
            <li>
              <p>
                O termo “Você” neste instrumento refere-se aos
                Contratantes.&nbsp;
              </p>
            </li>
            <li>
              <p>O termo “Nós” refere-se à Empresa.&nbsp;</p>
            </li>
            <li>
              <p>
                A Plataforma possibilita que os Usuários se contatem e negociem
                entre si diretamente, sem que a Empresa intervenha no contato,
                na negociação ou na efetivação dos negócios, não sendo, nesta
                qualidade, fornecedor e/ou intermediadora de quaisquer Serviços
                anunciados por seus Usuários na Plataforma.
              </p>
            </li>
            <li>
              <p>
                Na qualidade de classificado de Serviços e provedora de
                aplicações de internet, a Empresa não impõe ou interfere em
                qualquer negociação sobre condição, valor, qualidade, forma ou
                prazo da contratação entre os Contratantes e Pintores, tampouco
                garante a qualidade, ou entrega dos Serviços contratados entre
                os Usuários.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p>
        O Portal permite o acesso ao uso da Plataforma, através do licenciamento
        de subdomínios de internet de propriedade da SMART TECNOLOGIA EM VENDAS
        S/A, (CNPJ 20.893.968/0001-00 ) disponibilizado pela Internet para
        determinados dispositivos conectados à Internet, tais como dispositivos
        móveis (Android, IOS, Windows Mobile), servidores, computadores
        (desktops) e/ou outros aparelhos compatíveis com a Plataforma.&nbsp;
      </p>

      <ol start={2}>
        <li>
          <p>
            <u>
              <strong>Aceitação dos Termos de Uso e Políticas</strong>
            </u>
          </p>
        </li>
      </ol>

      <ol>
        <ol>
          <li>
            <p>
              <span size={3}>
                Ao clicar NO BOTÃO "EU CONCORDO" para cadastrar e integrar a
                Plataforma, o Usuário reconhece que analisou e aceitou as
                condições de uso e confirma que concorda com este&nbsp;
              </span>
              <span size={3}>Termos de Uso para Contratantes</span>
              <span size={3}>
                . A Empresa pode, a qualquer momento, revisar estes Termos de
                Uso&nbsp;
              </span>
              <span size={3}>para Contratantes</span> e/ou nossa Política de
              Privacidade. Você está vinculado a tais revisões e, portanto, deve
              visitar periodicamente esta página para determinar os termos de
              uso e política de privacidade atuais aos quais Você está
              vinculado. O uso da Plataforma após alterações significa que você
              concorda em estar vinculado aos Termos de Uso e Política de
              Privacidade que você visita.
            </p>
          </li>
          <li>
            <p>
              Os presentes Termos de Uso para Contratantes e os direitos e as
              obrigações aqui contidos são complementares aos{" "}
              <u>
                <a href="https://www.sherwin-williams.com/terms-of-use">
                  Termos de Uso - Sherwin-Williams
                </a>
              </u>
              , que se aplicam a todas as páginas da web, sites, portais,
              aplicativos e páginas de mídia social (coletivamente, “Sites”) que
              vinculam a Sherwin-Williams Company, juntamente com suas
              subsidiárias, afiliadas e marcas (coletivamente, “Empresa”), por
              referência.
            </p>
          </li>
        </ol>
      </ol>
      <ul>
        <li>
          <p>
            <em>
              <strong>
                DETALHES DO CADASTRO E CONDIÇÕES PARA USO DA PLATAFORMA
              </strong>
            </em>
          </p>
        </li>
      </ul>

      <ol start={3}>
        <li>
          <p>
            <u>
              <strong>Capacidade para cadastrar-se como Contratantes:</strong>
            </u>
          </p>
        </li>
      </ol>

      <ol>
        <ol>
          <li>
            <p>
              A Plataforma está disponível para pessoas físicas habilitadas à
              prática de todos os atos da vida civil e pessoas jurídicas
              regularmente inscritas nos cadastros de contribuintes federal e
              estaduais que tenham capacidade legal e econômica para contratar
              os Serviços.
            </p>
          </li>
        </ol>
      </ol>

      <ol>
        <ol start={2}>
          <li>
            <p>
              Não podem utilizar a Plataforma, assim, pessoas que não gozem
              dessa capacidade, inclusive menores de idade, pessoas relativa ou
              absolutamente incapazes, ou que tenham sido inabilitadas no
              Plataforma, temporária ou definitivamente.
            </p>
          </li>
        </ol>
      </ol>

      <ol start={4}>
        <li>
          <p>
            <u>
              <strong>Realizando o Cadastro para Acesso à Plataforma</strong>
            </u>
          </p>
        </li>
      </ol>

      <ol>
        <ol>
          <li>
            <p>
              Para se cadastrar na Plataforma é necessário o preenchimento
              completo de todos os dados pessoais exigidos no momento do
              cadastramento para que o Usuário esteja habilitado a utilizar a
              Plataforma.
            </p>
          </li>
        </ol>
      </ol>

      <ol>
        <ol start={2}>
          <li>
            <p>
              É vedada a criação de mais de um cadastro por Usuário, não podendo
              haver duplicidade de dados em nenhum caso, somente sendo permitida
              a vinculação de um cadastro por CPF, telefone ou e-mail. Em caso
              de multiplicidade de cadastros elaborados por um só Usuário, a
              Empresa reserva-se o direito de, a seu exclusivo critério e sem
              necessidade de prévia anuência ou comunicação aos Usuários,
              inabilitar todos os cadastros existentes e impedir eventuais
              cadastros futuros vinculados a estes.
            </p>
          </li>
        </ol>
      </ol>

      <ol>
        <ol start={4}>
          <li>
            <p>
              Apenas será confirmado o cadastramento dos Usuários que
              preencherem todos os campos obrigatórios do cadastro, com
              informações exatas, precisas e verdadeiras.
            </p>
          </li>
        </ol>
      </ol>

      <ol>
        <ol start={5}>
          <li>
            <p>
              O Usuário está ciente que, a partir do cadastro de interesse ao
              acesso à Plataforma e adesão ao presente Termos de Uso para
              Contratantes, a Empresa poderá pedir documentos adicionais, se
              achar necessário, para o procedimento de validação do cadastro e
              autorização de uso da Plataforma.
            </p>
          </li>
        </ol>
      </ol>

      <ol>
        <ol start={6}>
          <li>
            <p>
              A Empresa poderá exigir, ainda, a qualquer momento, que sejam
              enviadas as versões digitalizadas e/ou originais de documentos
              relativos aos dados pessoais cadastrados.
            </p>
          </li>
        </ol>
      </ol>

      <ol>
        <ol start={7}>
          <li>
            <p>
              Após a informação dos dados e/ou documentos solicitados aos
              Usuários interessados ao acesso à Plataforma, estes deverão, para
              finalização do cadastro, declarar o seu expresso aceite e
              concordância com estes Termos de Uso para Contratantes.
            </p>
          </li>
        </ol>
      </ol>

      <ol>
        <ol start={8}>
          <li>
            <p>
              Com o aceite a este Termos de Uso para Contratantes, o Usuário,
              declara estar ciente e possuir permissão e consentimento
              específico e expresso, na forma exigida pela legislação vigente,
              para autorizar, expressamente, a verificação, pela Empresa, das
              informações por ele declaradas, podendo esta verificação ser feita
              por meio de pesquisas em bases públicas e/ou privadas, bem como
              reconhece expressamente que:
            </p>
          </li>
        </ol>
      </ol>

      <ol>
        <ol>
          <ol>
            <li>
              <p>
                A Empresa poderá enviar as informações fornecidas para
                parceiros, em conformidade com a nossa Política de Privacidade.
                Tais informações poderão ser usadas pelos parceiros e por outras
                instituições a eles ligadas para verificar a identificar o
                Usuário a fim de conferir os dados pessoas informados. A Empresa
                e parceiros poderão entrar em contato com o Usuário, através das
                formas de contato informadas ao realizar o cadastro de interesse
                no acesso à Plataforma;
              </p>
            </li>
          </ol>
        </ol>
      </ol>

      <ol>
        <ol>
          <ol start={2}>
            <li>
              <p>
                Autoriza a Empresa, parceiros e por outras instituições a eles
                ligadas a realizar verificações adicionais de identidade e
                indícios de fraude que poderiam ser cometidas pelo Usuário e
                outras pessoas físicas ou representantes legais (como
                proprietário, sócio ou diretor), assim como outros membros ou
                diretores.&nbsp;
              </p>
            </li>
          </ol>
        </ol>
      </ol>

      <ol>
        <ol start={9}>
          <li>
            <p>
              Após a pesquisa e verificação das informações mencionadas acima, a
              Empresa poderá, a seu único e exclusivo critério, aprovar o
              cadastro e autorizar acesso à Plataforma. Nesse caso, o Usuário
              será informado através das próprias Aplicações ou por e-mail.
            </p>
          </li>
          <li>
            <p>
              Note que, ainda que o Usuário realize o cadastro de interesse ao
              acesso à Plataforma, a Empresa poderá negá-lo e não
              aprovar/autorizar acesso à Plataforma.
            </p>
          </li>
          <li>
            <p>
              A Empresa se reserva o direito de, unilateralmente e sem prévio
              aviso, recusar qualquer solicitação de cadastro e de cancelar um
              cadastro previamente aceito.
            </p>
          </li>
        </ol>
        <li>
          <p>
            <u>
              <strong> Login e Senha de acesso à Plataforma&nbsp;</strong>
            </u>
          </p>
          <ol>
            <li>
              <p>
                <span size={3}>
                  O acesso e o uso da Plataforma é direcionado exclusivamente
                  aos Usuários definidos no item 1.2 deste&nbsp;
                </span>
                <span size={3}>Termos de Uso para Contratantes</span>
                <span size={3}>
                  &nbsp;mediante a concessão de login e senha pessoais e
                  intransferíveis,&nbsp;
                </span>
                comprometendo-se a não informar a terceiros esses dados e
                responsabilizando-se integralmente pelo uso e operações
                efetuadas em sua conta.&nbsp;
              </p>
            </li>
            <li>
              <p>
                A Empresa fornecerá: (i) login e senha individuais e de uso
                exclusivo de cada um dos Usuários pessoais físicas; (ii) login e
                senha de administrador de uso exclusivo dos Usuários pessoas
                jurídicas.&nbsp;
              </p>
              <ol>
                <li>
                  <p>
                    O login e senha de administrador são de inteira
                    responsabilidade do Usuário pessoa jurídica e também são
                    pessoais e intransferíveis, não podendo ser utilizados por
                    mais de um de seus empregados e/ou terceiros subcontratados.
                    A pessoa individualmente autorizada e responsável pelo login
                    e senha de administrador será definida exclusivamente pelo
                    Usuário pessoa jurídica, durante o processo de
                    cadastramento, sendo, portanto, de seu exclusivo
                    conhecimento e responsabilidade, não podendo ser imputadas,
                    sob hipótese alguma, qualquer responsabilidade à Empresa
                    pelo seu uso indevido ou quebra de seu sigilo por terceiros
                    não autorizados, ou mesmo terceiros autorizados que façam
                    uso ilegal ou indevido da senha.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Caberá ao Usuário pessoa jurídica cuidar para a constante
                    atualização da senha de seu login de administrador ou
                    pessoas autorizadas, como também promover o cancelamento
                    (desativação) imediato de senhas em caso de inatividade ou
                    desligamento de empregado ou pessoa autorizada que fazia uso
                    da senha.&nbsp;
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                A senha concedida e entregue pela Empresa para o primeiro acesso
                ao Portal deverá ser atualizada pelo Usuário no primeiro acesso.
              </p>
            </li>
            <li>
              <p>
                O sigilo e a guarda do login e senha são de inteira
                responsabilidade do Usuário. É expressamente proibido ao Usuário
                ceder login e senha à terceiros, a título gratuito ou oneroso,
                uma vez que ela é sigilosa, pessoal e intransferível.
              </p>
            </li>
            <li>
              <p>
                Você desde já declara estar ciente que Empresa não assume
                nenhuma responsabilidade em caso de roubo, perda, alteração ou
                uso indevido de suas informações pessoais, sendo as informações
                cadastrais protegidas por login e senha, para a segurança e
                privacidade dos Usuários. Será de responsabilidade do Usuário a
                proteção de tais dados contra a utilização de terceiros, sendo
                os atos praticados após sua identificação, no Portal, com login
                e senha considerados de exclusiva responsabilidade dos Usuários.
              </p>
            </li>
            <li>
              <p>
                Os Usuários comprometem-se a informar a Empresa imediatamente, e
                por meio seguro, a respeito de qualquer uso não autorizado de
                seu login e senha, bem como seu acesso não autorizado por
                terceiros.&nbsp;
              </p>
            </li>
            <li>
              <p>
                A Empresa, sem prejuízo das demais sanções previstas neste
                Termos de Uso, poderá promover, a qualquer tempo, e sem aviso
                prévio, o cancelamento do login e senha em razão: (i) de mau uso
                ou compartilhamento da senha; (ii) de medida de segurança, ao
                digitar senha incorreta ou não cadastrada por 3(três) vezes
                consecutivas; (iii) de uso não autorizado da Plataforma, e (iii)
                violação à leis e regulamentos.
              </p>
            </li>
            <li>
              <p>
                Em nenhuma hipótese será permitida a cessão, venda, aluguel ou
                outra forma de transferência da conta. Não se permitirá, ainda,
                a criação de novos cadastros por pessoas cujos cadastros
                originais tenham sido cancelados por infrações às políticas da
                Plataforma.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ul>
        <li>
          <p>
            <em>
              <strong> CONDUTA DO USUÁRIO</strong>
            </em>
          </p>
        </li>
      </ul>
      <ol start={6}>
        <ol>
          <li>
            <p>
              O Usuário da Plataforma concorda, se obriga e responsabiliza em:
            </p>
          </li>
        </ol>
      </ol>
      <ol type="a">
        <li>
          <p>não fraudar de qualquer forma a Plataforma;</p>
        </li>
        <li>
          <p>
            não utilizar meios automatizados ou eletrônicos para acessar o
            Portal (inclusive,&nbsp;robots, spiders,&nbsp;scripts,&nbsp;entre
            outros);
          </p>
        </li>
        <li>
          <p>
            Manter seguro o ambiente de seus dispositivos de acesso à
            Plataforma, valendo-se de ferramentas específicas para tanto, tais
            como antivírus, firewall, entre outras, de modo a contribuir para a
            prevenção de riscos eletrônicos;
          </p>
        </li>
        <li>
          <p>
            utilizar sistemas operacionais atualizados e eficientes para a plena
            utilização da Plataforma e&nbsp;
          </p>
        </li>
        <li>
          <p>
            equipar-se e responsabilizar-se pelos dispositivos de hardware
            necessários para o acesso à Plataforma, bem como pelo acesso desses
            à internet.
          </p>
        </li>
        <li>
          <p>
            não carregar, postar, enviar ou transmitir qualquer material que
            contenha vírus de software ou qualquer outro código de computador,
            arquivos ou programas projetados para interromper, destruir ou
            limitar a funcionalidade de qualquer software de computador ou
            equipamento de hardware ou telecomunicações associado à
            Plataforma.&nbsp;
          </p>
        </li>
        <li>
          <p>
            não interferir com os servidores ou redes conectadas à Plataforma ou
            violar qualquer um dos procedimentos, políticas ou regulamentos de
            redes conectadas à Plataforma, dos quais os termos são incorporados
            aqui.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Não se passar por qualquer outra pessoa enquanto acessa a
            Plataforma,&nbsp;
          </p>
        </li>
        <li>
          <p>
            Não exibir publicamente qualquer conteúdo em ou em conexão com
            qualquer outro site ou aplicativo, ou&nbsp;
          </p>
        </li>
        <li>
          <p>Não usar a Plataforma para qualquer finalidade ilícita.&nbsp;</p>
        </li>
      </ol>
      <ol start={6}>
        <ol>
          <li>
            <p>
              Os dados de contato do Contratante nunca serão disponibilizados ao
              Pintor pela Empresa, devendo de livre vontade ser informados pelo
              Contratante no momento da negociação.
            </p>
          </li>
          <li>
            <p>
              A Empresa não será responsável em hipótese alguma pelo insucesso
              de uma eventual negociação entre Pintores e Contratantes, qualquer
              que seja o motivo, incluindo dados cadastrais desatualizados ou
              incorretos inseridos pelos Usuários.
            </p>
          </li>
        </ol>
      </ol>
      <ul>
        <li>
          <p>
            <em>
              <strong>CONTEÚDO DOS USUÁRIOS</strong>
            </em>
          </p>
        </li>
      </ul>
      <ol start={7}>
        <ol>
          <li>
            <p>
              Todas as informações, conteúdos, fotos, imagens, contribuições e
              submissões que Você fornece em conexão com a Plataforma que
              permitem tal postagem pública, contribuições ou participação, são
              referidos como "Conteúdo do Usuário".&nbsp;
            </p>
          </li>
          <li>
            <p>
              Você concorda ainda em não publicar e fornecer informações falsas,
              imprecisas, desatualizadas e incompletas sobre Você e seu Conteúdo
              de Usuário.&nbsp;
            </p>
          </li>
          <li>
            <p>
              A Empresa não se responsabiliza pela exatidão, veracidade ou
              confiabilidade de qualquer Conteúdo do Usuário e, por este meio,
              se isenta de toda e qualquer responsabilidade em relação ao
              Conteúdo do Usuário ou qualquer dependência do Conteúdo do
              Usuário. Você é responsável pelo seu Conteúdo de Usuário.
            </p>
          </li>
          <li>
            <p>
              Você reconhece e concorda que o Conteúdo do Usuário pode ser
              disponibilizado pela Empresa para visualização, classificação,
              revisão ou comentários do público. Ao fornecer conteúdo do
              Usuário, ou concordar com o uso da Empresa de qualquer Conteúdo do
              Usuário, você renuncia a quaisquer expectativas de privacidade que
              você possa ter em relação ao Conteúdo do Usuário&nbsp;
            </p>
          </li>
          <li>
            <p>
              Ao fornecer conteúdo do Usuário, ou concordar com o uso do
              Conteúdo do Usuário pela Empresa, você representa e garante que o
              Conteúdo do Usuário cumpre todas as leis aplicáveis, regras e
              regulamentos, e não infringe, se apropria ou viola de qualquer
              direito autoral, marca registrada, direitos de propriedade,
              direitos de privacidade ou publicidade de qualquer pessoa ou
              qualquer outro direito de terceiros, bem como que você tem todo e
              irrestrito direito de licenciar e fornecer o Conteúdo do Usuário à
              Empresa, livre de quaisquer reivindicações ou ônus, e que você tem
              os direitos de toda e qualquer parte do Conteúdo do Usuário para
              usar.&nbsp;
            </p>
          </li>
          <li>
            <p>
              Você reconhece e concorda que a Empresa não tem obrigação de
              postar, exibir ou disponibilizar publicamente seu Conteúdo do
              Usuário. A Empresa não se responsabiliza pela confidencialidade do
              Conteúdo do Usuário.
            </p>
          </li>
          <li>
            <p>
              A empresa terá o direito, mas não a obrigação, de monitorar o
              Conteúdo do Usuário para determinar o cumprimento destes Termos de
              Uso para Contratantes e quaisquer outras regras operacionais
              estabelecidas pela Empresa, bem como determinar o cumprimento das
              leis aplicáveis, incluindo, mas não se limitando às leis de
              propriedade intelectual.&nbsp;
            </p>
          </li>
          <li>
            <p>
              A empresa pode editar, recusar-se a postar, bloquear o acesso ou
              remover todo ou parte de qualquer Conteúdo do Usuário a seu
              critério por qualquer motivo ou nenhuma razão, incluindo sem
              limitação se a Empresa suspeitar que o Conteúdo do Usuário é
              falso, impreciso, infringindo ou de outra forma ilegal, ou o
              endereço de e-mail ou outras informações fornecidas com o Conteúdo
              do Usuário são inválidas, falso ou enganoso.&nbsp;
            </p>
          </li>
          <li>
            <p>
              A empresa pode divulgar o Conteúdo do Usuário para qualquer
              autoridade reguladora ou criminal ou para terceiros.
            </p>
          </li>
        </ol>
      </ol>
      <ul>
        <li>
          <p>
            <em>
              <strong>POLÍTICA DE COMENTÁRIOS E CHAT</strong>
            </em>
          </p>
        </li>
      </ul>
      <ol start={8}>
        <ol>
          <li>
            <p>
              Após os Serviços serem prestados, os Contratantes terão
              oportunidade de avaliar sua experiência por meio de comentários na
              Plataforma sobre atendimento e Serviços prestados pelo
              Pintor.&nbsp;
            </p>
          </li>
          <li>
            <p>
              Você nos autoriza a publicar e utilizar tais comentários e
              feedbacks na Plataforma, bem como a analisar, processar e tratar
              esse feedback de forma isolada ou agregada a fim de avaliar os
              serviços prestados.&nbsp;
            </p>
          </li>
          <li>
            <p>
              <span size={3}>
                Os Pintores serão ranqueados na Plataforma com até 05 (cinco)
                estrelas, onde cada 02 feedbacks satisfatórios equivalem a 01
                (uma) estrela. Serão necessários ao menos 10 (dez) feedbacks
                satisfatórios para que o Pintor atinja 05 (cinco)
                estrelas.&nbsp;
              </span>
            </p>
            <ol>
              <li>
                <p>
                  Você, ao realizar feedbacks, poderá ser beneficiado conforme
                  campanhas promocionais disponibilizadas pela Empresa, conforme
                  Regulamentos específicos para cada campanha promocional,
                  devendo aderir expressamente a cada um dos Regulamentos
                  durante a sua vigência para participação.&nbsp;
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </ol>

      <ol start={8}>
        <ol start={4}>
          <li>
            <p>
              A exclusivo critério da Empresa poderá ser permitido que o Usuário
              se utilize de software de comunicação online (“Chat”).&nbsp;
            </p>
          </li>
          <li>
            <p>
              Você é o único e exclusivo responsável pelos comentários e
              mensagens que enviar, publicar ou armazenar, sendo vedado o uso de
              linguagem: (i) indecorosa, (ii) preconceituosa, (iii)
              desrespeitosa, (iv) discriminatória, (v) injuriosa, (vi)
              caluniosa, (vii) difamatória e/ou que de qualquer forma atentem
              contra a dignidade, a imagem, a reputação, a honra, a moral, a
              integralidade ou qualquer outro direito de qualquer pessoa,
              independentemente de sua nacionalidade, etnia ou religião, (viii)
              que contenha dados (mensagens, informação, imagens) subliminares,
              (ix) contenha dados ou informações que constituem ou possam
              constituir crime (ou contravenção penal) ou que possam ser
              entendidas como incitação à prática de crimes (ou contravenção
              penal); (x) ofenda à liberdade de crença e às religiões; (xi)
              promova discriminação, intolerância, racismo, ódio, assédio ou
              dano contra qualquer indivíduo ou grupo, (xii) promova violência
              ou ações que sejam ameaçadoras a qualquer outra pessoa ou animal;
              (xiii) viole qualquer lei ou seja inapropriada, (xiv) atente
              contra a ordem pública, os bons costumes e/ou qualquer norma
              jurídica, (xv) faça propaganda eleitoral ou divulgue opinião
              favorável ou contra partido ou candidato e (xvi) viole direitos de
              Propriedade Intelectual, em especial direitos autorais, de marcas
              e da personalidade. Você reconhece que é o único responsável por
              todo o conteúdo por Você publicado na Plataforma, isentando a
              Empresa de responsabilidade relacionada a quaisquer reclamações,
              prejuízos, perdas e danos causados à nós, parceiros, demais
              Usuários e/ou quaisquer terceiros em decorrência da publicação ou
              envio desses comentários e mensagens.&nbsp;
            </p>
          </li>
          <li>
            <p>
              A Empresa pode unilateralmente excluir o cadastro dos Usuários
              quando verificado que a conduta do Usuário é ou será prejudicial
              ou ofensiva a outros Usuários, à Empresa e seus funcionários ou a
              terceiros.
            </p>
          </li>
        </ol>
      </ol>
      <ul>
        <li>
          <p>
            <em>
              <strong>WEBSITES, RECURSOS E PUBLICIDADE DE TERCEIROS</strong>
            </em>
          </p>
        </li>
      </ul>
      <ol>
        <li>
          <p>
            Em virtude de parcerias ou por acreditarmos que possa ser do
            interesse do Usuários, poderá ser conectar à Plataforma, quando
            necessário e/ou a exclusivo critério da Empresa, outros websites e
            funcionalidades da internet, sem que isso signifique que esses
            websites sejam de propriedade ou operados por nós, bem como espaços
            para veiculação de conteúdos publicitários, próprios ou de
            terceiros, em conformidade com a legislação vigente e os bons
            costumes comerciais.
          </p>
        </li>
        <li>
          <p>
            As Licenciadas não têm nenhum controle sobre tais conteúdos,
            funcionalidades, serviços ou segurança das informações, serviços
            e/ou produtos quando realizado o acesso a sites e/ou aplicações de
            terceiros, ainda que o acesso seja feito por meio da Plataforma e,
            neste caso, a Empresa não é responsável, direta ou indiretamente,
            por qualquer dano ou perda causada ou alegada, com relação ao uso ou
            confiança em quaisquer de tais conteúdos, bens ou website
            disponíveis em ou por meio de qualquer website ou recurso. Em vista
            disso, devem sempre ser verificados os termos de uso e política de
            privacidade quando do acesso às páginas pertencentes a terceiros.
            Tais páginas podem possuir termos de uso e política de privacidade
            próprias, estando o seu uso e dados coletados sujeito a tais
            disposições, alheias ao controle ou responsabilidade da Empresa.
          </p>
        </li>
      </ol>
      <ul>
        <li>
          <p>
            <em>
              <strong>INFORMAÇÕES CONFIDENCIAIS</strong>
            </em>
          </p>
        </li>
      </ul>
      <ol start={9}>
        <li>
          <br />
          <ol>
            <li>
              <p>
                Todo e qualquer dados e informações, sejam elas informações
                comerciais, financeiras, operacionais, técnicas, econômicas,
                contábeis, mercadológicas regulatórias, de marketing, de
                programação, jurídica ou de qualquer outra natureza,
                disponibilizadas na Plataforma aos Usuários (“Conteúdo”) serão
                consideradas Informações Confidenciais reveladas aos Usuários e
                deverão ser guardadas em segredo, não devendo ser reveladas a
                terceiros ou utilizadas, sem a devida autorização da
                Empresa.&nbsp;
              </p>
            </li>
            <li>
              <p>
                O Usuário obriga-se, neste ato, por si, não revelar, divulgar ou
                publicar na internet e em quaisquer redes sociais qualquer
                Informação Confidencial que não aquelas expressamente
                autorizadas pela Empresa.&nbsp;
              </p>
            </li>
            <li>
              <p>
                Caso o Usuário venha a ser legalmente obrigado a revelar
                qualquer conteúdo, dados e informações disponibilizada na
                Plataforma, por qualquer juízo ou autoridade governamental
                competente, o Usuáriodeverá notificar em prazo razoável a
                Empresa de tal ordem, para que este possa tomar as medidas
                cabíveis para resguardar os seus direitos ou dispensar o
                Usuáriode cumprir tal ordem, a seu exclusivo critério, sendo que
                o Usuário deverá cooperar com a Empresa nos esforços a esse
                propósito. Se, na falta de uma medida legal cabível ou de uma
                autorização para o não cumprimento da ordem judicial, o
                Usuárioestiver obrigado a revelar qualquer Informação
                Confidencial, o Usuáriorevelará somente aquela parte da
                Informação Confidencial que for estritamente necessária para
                cumprir com tal ordem judicial, conforme orientação expressa da
                Empresa. O Usuário cooperará com o(s) advogado(s) da Empresa na
                obtenção das medidas legais ou outras garantias de que as
                informações fornecidas receberão tratamento confidencial.&nbsp;
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ul>
        <li>
          <p>
            <em>PROPRIEDADE INTELECTUAL</em>
          </p>
        </li>
      </ul>
      <ol start={10}>
        <li>
          <br />
          <ol>
            <li>
              <p>
                O Usuário, por meio do login e senha, recebe uma sublicença
                limitada, não transferível, não exclusiva, livre de royalties e
                revogável para acessar e navegar na Plataforma em um dispositivo
                autorizado pela Empresa. Você reconhece e concorda que a Empresa
                concede ao Usuário uma sublicença apenas para uso e desta forma
                não lhe transfere os direitos sobre a Plataforma.&nbsp;
              </p>
            </li>
            <li>
              <p>
                Todos os direitos relativos à Plataforma aqui descritos,
                incluindo suas funcionalidades e conteúdo, são de titularidade
                da Empresa ou de terceiros, protegido pela lei de direitos
                autorais e de propriedade industrial.&nbsp;
              </p>
            </li>
            <li>
              <p>
                Todos os Anúncios, marcas, textos e conteúdos da Empresa,
                Anunciantes e/ou terceiros veiculados através da Plataforma são
                de propriedade de seus respectivos titulares.&nbsp;
              </p>
            </li>
            <li>
              <p>
                É expressamente proibido usar, copiar, reproduzir, modificar,
                adaptar, traduzir, derivar, descompilar, realizar engenharia
                reversa, tentar descobrir o código fonte de qualquer parte da
                Plataforma, desmembrar, publicar, transmitir, distribuir,
                executar, licenciar, vender ou de alguma forma explorar, para
                qualquer finalidade, o nome, marca, logotipos, e quaisquer
                outros sinais distintivos de titularidade ou utilizados pela
                outra Parte, softwares, obras intelectuais, patentes e qualquer
                conteúdo de titularidade de terceiros, sem o consentimento
                prévio de referido titular. O presente Termos de Uso para
                Contratantes não produz nenhum efeito de cessão, licença ou
                prerrogativa em relação aos produtos, ou, ainda, com relação às
                marcas ou sinais, nem mesmo a qualquer patente, registro, ou
                título de propriedade.
              </p>
            </li>
            <li>
              <p>
                Como regra, é necessária uma licença de uso de marca para usar
                marcas comerciais, marcas de serviço e logotipos usados e
                exibidos na Plataforma ("Marcas") independentemente de
                registradas ou não pela Empresa e/ou terceiros. Este Termo de
                Uso para Contratantes não conta como “permissão para usar”
                nenhuma Marca e não há casos de uso pré-aprovados de “código
                aberto”.&nbsp;
              </p>
            </li>
            <li>
              <p>
                É expressamente proibido utilizar, sob qualquer forma ou
                pretexto, as Marcas da Empresa, suas reproduções parciais ou
                integrais ou ainda suas imitações, independentemente da
                destinação de tal uso.
              </p>
            </li>
            <li>
              <p>
                É vedada a utilização das Marcas ou de suas variações (incluindo
                erros de ortografia ou variações fonéticas) como nome de domínio
                ou parte de nome de domínio ou em qualquer nome de empresa, de
                qualquer tipo ou natureza, sob qualquer meio ou forma, inclusive
                por meio da criação de nomes de domínio ou e-mails. Todas as
                outras marcas, nomes de produtos, ou nomes de companhias que
                aparecem no site são de propriedade exclusiva de seus
                respectivos titulares.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ul>
        <li>
          <p>
            <em>
              SANÇÕES PELO DESCUMPRIMENTO DESTE TERMOS DE USO E PERDA DE ACESSO
              À PLATAFORMA
            </em>
          </p>
        </li>
      </ul>
      <ol start={11}>
        <li>
          <br />
          <ol>
            <li>
              <p>
                Sem prejuízo de eventual responsabilização penal e civil, em
                razão de: (i) violação ou infração deste Termos de Uso para
                Contratantes; (ii) uso indevido, não autorizado ou abusivo da
                Plataforma, e (iii) violar leis e regulamentos, a Empresa
                poderá, a qualquer momento, com ou sem aviso prévio:&nbsp;
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ol type="a">
        <ol type="i">
          <li>
            <p>Advertir o Usuário;&nbsp;</p>
          </li>
          <li>
            <p>
              Suspender, temporária ou definitivamente, o acesso do Usuário à
              Plataforma.
            </p>
          </li>
        </ol>
      </ol>
      <ol start={11}>
        <ol>
          <li>
            <p>
              Os Usuários, ainda, concordam em defender, indenizar e manter
              indene a Empresa, diretores, empregados e agentes, de e contra
              quaisquer encargos, ações ou demandas, incluindo, mas não limitado
              a honorários advocatícios, resultantes da sua eventual utilização
              indevida do Portal e/ou do Conteúdo.
            </p>
          </li>
          <li>
            <p>
              No caso de aplicação de quaisquer destas sanções, não assistirá
              aos Usuários direito a qualquer tipo de indenização ou
              ressarcimento por perdas e danos, lucros cessantes ou danos
              morais.
            </p>
          </li>
        </ol>
      </ol>
      <ul>
        <li>
          <p>
            <em>
              POLÍTICA DE PRIVACIDADE: ARMAZENAMENTO DOS DADOS E INFORMAÇÕES
            </em>
          </p>
        </li>
      </ul>
      <ol start={11}>
        <li>
          <p>
            <u>
              <strong>Obtenção dos Dados e Informações:</strong>
            </u>
          </p>
          <ol>
            <li>
              <p>
                Os dados e informações (sejam eles pessoais ou anonimizados)
                serão obtidos quando o Usuário interagir com a Plataforma,
                fornecendo as informações voluntariamente ou involuntariamente;
              </p>
            </li>
          </ol>
        </li>
      </ol>

      <ol start={11}>
        <li>
          <p>
            <u>
              <strong>Armazenamento dos Dados e Informações:</strong>
            </u>
          </p>
          <ol>
            <li>
              <p>
                Todos os dados e informações coletadas dos Usuários estarão
                armazenados em ambiente seguro, observado o estado da técnica
                disponível e criptografados, e somente poderão ser acessados por
                pessoas qualificadas e autorizadas pela Empresa. Considerando
                que nenhum sistema de segurança é absolutamente seguro, a
                Empresa se exime de quaisquer responsabilidades por eventuais
                danos e/ou prejuízos decorrentes de falhas, vírus ou
                invasões.&nbsp;
              </p>
            </li>
            <li>
              <p>
                A Empresa manterá os Dados Pessoais somente pelo tempo que for
                necessário para cumprir com as finalidades para as quais foram
                coletados, inclusive para fins de cumprimento de quaisquer
                obrigações legais, contratuais, de prestação de contas ou
                requisição de autoridades competentes.
              </p>
            </li>
            <li>
              <p>
                Para determinar o período de retenção adequado para cada tipo de
                Dado Pessoal tratado pela Empresa, sempre consideramos a
                quantidade, a natureza e a sensibilidade de tais Dados, o risco
                potencial de danos decorrentes do seu uso não autorizado ou da
                sua divulgação, a finalidade do tratamento e se podemos alcançar
                tais propósitos por outros meios, e os requisitos legais
                aplicáveis.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <u>
              <strong>Uso dos Dados e Informações:</strong>
            </u>
          </p>
          <ol>
            <li>
              <p>
                O Usuário, aceita de maneira expressa, consciente e voluntária
                que as suas informações submetidas à Empresa (“Dados Pessoais”
                e/ou “Dados Pessoais Sensíveis”), sejam recompiladas,
                armazenadas para sua utilização pela Empresa. A Empresa poderá
                utilizar as informações coletadas para:
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p>(i)&nbsp;Aplicar estes Termos de Uso para Contratantes;</p>
      <p>(ii)&nbsp;Prestar assistência técnica;</p>
      <p>
        (iii)&nbsp;Responder a eventuais dúvidas e solicitações dos Usuários;
      </p>
      <p>(iv)&nbsp;Cumprir ordem legal ou judicial;</p>
      <p>
        (v)&nbsp;Constituir, defender ou exercer regularmente direitos em âmbito
        judicial ou administrativo;
      </p>
      <p>
        (vi)&nbsp;Elaborar estatísticas gerais, para identificação do perfil dos
        Usuários, desenvolvimento de campanhas da Empresa, mas sem realizar
        identificação pessoal, podendo inclusive disponibilizar tais informações
        a terceiros;
      </p>
      <p>(vii)&nbsp;Garantir a segurança dos Usuários;</p>
      <p>
        (viii)&nbsp;Manter atualizados os cadastros dos Usuários para fins de
        contato por telefone, correio eletrônico ou por outros meios de
        comunicação;
      </p>
      <p>
        <span size={3}>
          (ix) Informar a respeito de novidadess, promoções e eventos da Empresa
          e de seus parceiros comerciais;&nbsp;
        </span>
      </p>
      <p>
        (x)&nbsp;direcionar anúncios aos titulares dos Dados e a terceiros de
        quaisquer espécies, através de quaisquer meios ou canais, inclusive, mas
        não limitado, a meios eletrônicos, sobre parcerias da Empresa, serviços,
        ainda que exclusivos, dos parceiros da Empresa e campanhas relacionadas;
      </p>
      <p>
        (xi) Juntar ou coletar os dados pessoais e incorporá-los e armazená-los
        em nossa base de dados;
      </p>
      <p>
        <span size={3}>
          (xii) Utilizar os dados fornecidos em campanhas de comunicação,
          divulgação e promoção ou oferta de produtos, atividades ou serviços
          desenvolvidos como parte de estratégias internas da Empresa;&nbsp;
        </span>
      </p>
      <p>
        (xiii) Utilizá-los para fins administrativos internos ou comerciais tais
        como: referências comerciais de experiência, estudos de mercado,
        análises estatísticas, realização de pesquisas sobre satisfação,
        oferecimento ou reconhecimento de benefícios próprios da Empresa;
      </p>
      <p>
        <span size={3}>
          (xiv) Conservar registros históricos dentro da Plataforma e manter
          contato com os Usuários,&nbsp;
        </span>
      </p>
      <p>
        <span size={3}>
          (xv) Verificar, comprovar ou validar os dados entregues,&nbsp;
        </span>
      </p>
      <p>
        <span size={3}>
          (xvi) Estudar e analisar a informação fornecida para o acompanhamento
          e melhoramento dos produtos e do serviço,&nbsp;
        </span>
      </p>
      <p>
        <span size={3}>
          (xvii) Entregar e transmitir a informação recolhida a terceiros com os
          que a Empresa mantenha contratos comerciais ou de armazenamento e
          administração dos dados pessoais, sob os padrões de segurança e
          confidencialidade aos quais Empresa está obrigada de acordo com o
          conteúdo das leis pertinentes;&nbsp;
        </span>
      </p>
      <p>
        <span size={3}>
          (xviii) Transmitir dados em nível nacional ou internacional para
          fornecedores com os quais a Empresa desenvolve atividades em
          conformidade com seu objeto social. Da mesma forma, uma transmissão
          pode ser feita para os aliados/parceiros estratégicos da empresa para
          realizar atividades de marketing, publicidade e promoções associadas
          ao objeto social; Tudo isso de acordo com as disposições da legislação
          brasileira.&nbsp;
        </span>
      </p>
      <p>
        <span size={3}>
          (xix) Juntar, possuir, manusear e utilizar a informação para realizar
          controle e prevenção de fraude, controle e prevenção de lavagem de
          ativos e financiamento do terrorismo,&nbsp;
        </span>
      </p>
      <ol start={11}>
        <ol start={2}>
          <li>
            <p>
              A quantidade e o tipo de Dados Pessoais coletados pela Empresa
              variam conforme o uso da Plataforma pelo Usuário. Para esclarecer
              essas questões, segue abaixo algumas finalidades para as quais os
              Dados Pessoais dos proponentes podem ser utilizados, dependendo da
              sua forma de utilização da Plataforma:&nbsp;
            </p>
            <table cellPadding={4} cellSpacing={0} width={644}>
              <tbody>
                <tr>
                  <td width="15.737704918032787%">
                    <p>Origem</p>
                  </td>
                  <td width="30.327868852459016%">
                    <p>Tipo de Dados Coletados</p>
                  </td>
                  <td width="32.622950819672134%">
                    <p>Finalidade</p>
                  </td>
                  <td width="21.311475409836067%">
                    <p>Bases Legais</p>
                  </td>
                </tr>
                <tr>
                  <td width="15.737704918032787%">
                    <p>Cadastro na Plataforma</p>
                  </td>
                  <td width="30.327868852459016%">
                    <p>
                      Dados de cadastro: nome, e‑mail, telefone, CPF, data de
                      nascimento, endereço residencial.
                    </p>
                  </td>
                  <td width="32.622950819672134%">
                    <p>
                      Cadastro na Plataforma, envio de Ofertas, envio de
                      brindes, contatos telefônicos (ligação, SMS, push e
                      WhatsApp). &nbsp; Análise do perfil de consumo,
                      perfilhamento para fins de inteligência de mercado e envio
                      de publicidade própria e de terceiros, landing pages e
                      pesquisas. Verificação da regularidade e veracidade dos
                      dados fornecidos no cadastro, autorizando, ainda, a
                      obtenção de dados adicionais bem como fornecimento das
                      informações a empresas para detectar fraudes, incluindo
                      uso fraudulento das Aplicações.
                    </p>
                  </td>
                  <td width="21.311475409836067%">
                    <p>
                      Consentimento, Execução de Contrato e Legítimo Interesse
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="15.737704918032787%">
                    <p>Interação com a Plataforma</p>
                  </td>
                  <td width="30.327868852459016%">
                    <p>
                      Dados de navegação: endereço do IP, IMEI do dispositivo do
                      Usuário, dados locacionais e comportamento de consumo na
                      Plataforma para análise de preferências.
                    </p>
                  </td>
                  <td width="32.622950819672134%">
                    <p>
                      Análise do perfil de consumo, perfilhamento para fins de
                      inteligência de mercado e envio de publicidade própria e
                      de terceiros. Avaliar e desenvolver novas funcionalidades,
                      tecnologias e melhorias para o ambiente digital.&nbsp;
                    </p>
                  </td>
                  <td width="21.311475409836067%">
                    <p>Consentimento, e Legítimo Interesse</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <p>
              <span size={3}>
                Os registros mencionados poderão ser utilizados pela Empresa,
                assim como no compartilhamento com terceiros. A tabela abaixo
                descreve os terceiros com quem compartilhamos dados pessoais:
              </span>
            </p>
          </li>
        </ol>
      </ol>

      <table cellPadding={4} cellSpacing={0} width={644}>
        <tbody>
          <tr>
            <td width="27.955271565495206%">
              <p>Categorias de Destinatários</p>
            </td>
            <td width="72.04472843450479%">
              <p>Razão do Compartilhamento</p>
            </td>
          </tr>
          <tr>
            <td width="27.955271565495206%">
              <p>Prestadores de Serviços</p>
            </td>
            <td width="72.04472843450479%">
              <p>
                Prestadores de serviços em favor da Empresa e que podem
                necessitar acessar determinados dados pessoais com o fim de nos
                fornecerem os seus serviços. Tais empresas incluem aquelas que
                contratamos para fornecer o serviço de apoio ao cliente, operar
                a infraestrutura técnica necessária para acesso à Plataforma e
                suas funcionalidades, ajudar a proteger e garantir os sistemas e
                serviços, bem como prestar assistência na comercialização dos
                produtos, serviços, eventos e promoções de parceiros nos quais a
                Empresa está envolvida.
              </p>
            </td>
          </tr>
          <tr>
            <td width="27.955271565495206%">
              <p>Parceiros publicitários</p>
            </td>
            <td width="72.04472843450479%">
              <p>
                Personalizar o conteúdo de publicidade que pode receber na
                Plataforma. Tais parceiros auxiliam-nos a apresentar anúncios e
                mensagens promocionais mais relevantes para você, que podem
                incluir publicidade baseada em interesse (publicidade
                comportamental online), publicidade contextual e publicidade
                genérica. Juntamente com os nossos parceiros publicitários,
                podemos processar determinados dados pessoais para auxiliar a
                compreender os seus interesses ou preferências, apresentando a
                você anúncios mais relevantes.
              </p>
            </td>
          </tr>
          <tr>
            <td width="27.955271565495206%">
              <p>
                Autoridades de observância da lei e de proteção de dados
                pessoais
              </p>
            </td>
            <td width="72.04472843450479%">
              <p>
                Cumprir uma obrigação legal nos termos da legislação aplicável,
                ou responder a um procedimento legal válido, como um mandado de
                busca, ordem judicial ou intimação.&nbsp;
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <ol start={11}>
        <ol start={4}>
          <li>
            <p>
              Você pode solicitar a exclusão dos seus Dados Pessoais das bases
              de dados que utilizamos para tratá-los. Todos os Dados coletados
              serão excluídos de nossos servidores quando você assim requisitar
              ou quando estes não forem mais necessários ou relevantes para lhe
              oferecermos os nossos serviços, salvo se houver qualquer outra
              razão para a sua manutenção, como eventual obrigação legal de
              retenção de Dados ou necessidade de preservação destes para
              resguardar direitos da Empresa.
            </p>
          </li>
        </ol>
      </ol>

      <ol start={11}>
        <li>
          <p>
            <u>
              <strong>Cookies</strong>
            </u>
          </p>
          <ol>
            <li>
              <p>
                <span size={3}>
                  O Usuário desde já autoriza a Empresa, a seu critério, a
                  armazenar, por meio de logs ou de qualquer outra ferramenta,
                  todas as informações submetidas, inclusive endereços de IP
                  (Internet Protocol, conjunto de números que identifica o
                  computador dos usuários), ações efetuadas na Plataforma,
                  seções da Plataforma acessadas, informações de data e horário
                  de suas ações e acessos, session ID, geolocalização, dados
                  sobre o dispositivo de acesso e cookies, perfil e
                  comportamento, dados gerais de navegabilidade, entre outras
                  informações. Tais dados serão utilizados pela Empresa para
                  oferecer ao Usuário uma melhor experiência na utilização da
                  Plataforma e para os fins descritos neste&nbsp;
                </span>
                <span size={3}>Termos de Uso para Pintores</span>.
              </p>
            </li>
            <li>
              <p>
                A Empresa poderá utilizar cookies para administrar as sessões de
                Usuários e armazenar preferências, rastrear informações, idioma
                selecionado, dentre outros. Cookies poderão ser utilizados
                independentemente de cadastro do Usuário na Plataforma.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p>
        (i)&nbsp;“Cookies” são arquivos de texto transferidos via servidor para
        o disco rígido e armazenados no dispositivo do usuário, que podem
        coletar informações como data e horário de acesso, histórico de
        navegação, preferências e nome do usuário.
      </p>
      <p>
        (ii)&nbsp;O Usuário tem a opção de aceitar ou recusar o uso de cookies
        em seu dispositivo, independente de cadastro na Plataforma, configurando
        seu dispositivo como desejar – neste caso, algumas funcionalidades da
        Plataforma podem ser limitadas.
      </p>
      <ul>
        <li>
          <p>
            <strong>
              <em>ISENÇÃO DE GARANTIAS E LIMITAÇÕES DE RESPONSABILIDADE</em>
            </strong>
          </p>
        </li>
      </ul>
      <ol start={16}>
        <li>
          <p>
            <u>
              <strong>Isenção de Responsabilidade de Garantia</strong>
            </u>
          </p>
          <ol>
            <li>
              <p>
                AFORA AS RESPONSABILIDADES PREVISTAS NESTES TERMOS DE USO, NÃO
                CONFERIMOS A VOCÊ NEM A QUALQUER USUÁRIO QUALQUER GARANTIA
                ADICIONAL, LIMITANDO-SE ÀS RESPONSABILIDADES AQUI DESCRITAS,
                EXPRESSAS OU IMPLÍCITAS, E TODAS ESSAS GARANTIAS, CONDIÇÕES E
                PROMESSAS PODEM SER EXCLUÍDAS DE ACORDO COM O QUE É PERMITIDO
                POR LEI SEM PREJUÍZO À EMPRESA E SEUS COLABORADORES. NÃO
                GARANTIMOS, REEMBOLSAMOS, RESSARCIMOS OU INDENIZAMOS QUALQUER
                USUÁRIO, PARCEIRO OU TERCEIRO A RESPEITO DE QUAISQUER OUTRAS
                DISPOSIÇÕES QUE NÃO AS AQUI PREVISTAS ORIGINALMENTE.&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span size={3}>
                  Nenhuma disposição deste Termos de Uso para Contratantes
                  deverá ser interpretada de modo a sugerir, implícita ou
                  expressamente, que existe qualquer outro tipo de relação entre
                  a Empresa e o Usuário senão aquela mútua a independentemente
                  estabelecida na qualidade&nbsp;
                </span>
                <span size={3}>
                  classificado de Serviços e provedora de aplicações de internet
                </span>
                .
              </p>
            </li>
            <li>
              <p>
                Não é estabelecido, por força deste Termos de Uso, qualquer tipo
                de cadeia de fornecimento, intermediação, joint-venture,
                franquia, associação, filial ou relação de emprego entre a
                Empresas, Usuários e/ou seus funcionários. O Pintor prestará os
                serviços de forma autônoma, respondendo como único e exclusivo
                responsável da mão de obra utilizada para a prestação dos
                Serviços avençados com os Contratantes.
              </p>
            </li>
            <li>
              <p>
                <span size={3}>
                  A Empresa não se responsabiliza por: (i) eventuais ações ou
                  omissões de terceiros, ainda que tais terceiros sejam seus
                  parceiros comerciais; (ii) atos praticados por Usuários que
                  fizeram registros fraudulentos ou mediante falsa declaração;
                  (iii) uso inadequado e/ou divulgação de dados pelos Usuários a
                  terceiros; (iv) pela utilização de dados de terceiros sem
                  qualquer autorização do respectivo titular; (v)&nbsp;
                </span>
                por qualquer transação realizada diretamente entre Contratante e
                Pintores, mesmo que as partes tenham se conhecido por meio da
                Plataforma; (ii) por quaisquer danos ou indenizações decorrentes
                de falha nos Serviços e/ou no cumprimento de obrigação por
                qualquer Usuário.
              </p>
            </li>
            <li>
              <p>
                É de exclusiva responsabilidade dos Usuários fornecer, atualizar
                e garantir a veracidade dos dados cadastrais, não cabendo à
                Empresa qualquer tipo de responsabilidade civil e/ou criminal
                resultante de dados inverídicos, incorretos ou incompletos
                fornecidos pelos Usuários.
              </p>
            </li>
            <li>
              <p>
                A Empresa somente poderá ser responsabilizado civilmente por
                danos decorrentes de conteúdo gerado por Usuários e terceiros
                se, após ordem judicial específica, não tomar as providências
                para, no âmbito e nos limites técnicos do seu serviço e dentro
                do prazo assinalado, tornar indisponível o conteúdo apontado
                como infringente, ressalvadas as disposições legais em
                contrário.
              </p>
            </li>
            <li>
              <p>
                A Plataforma está em contínuo desenvolvimento e pode conter
                erros e, por isso, o uso é fornecido “no estado em que se
                encontra” e sob risco do Usuários. Na extensão máxima permitida
                pela legislação aplicável, a Empresa e seus fornecedores
                isentam-se de quaisquer garantias e condições expressas ou
                implícitas incluindo, sem limitação, garantias de
                comercialização, adequação a um propósito específico,
                titularidade e não violação no que diz respeito à Plataforma e
                qualquer um de seus componentes ou ainda à prestação ou não de
                serviços de suporte. A Empresa não garante que a operação desta
                Plataforma seja contínua e sem defeitos.&nbsp;
              </p>
            </li>
            <li>
              <p>
                A Empresa não garante, declara ou assegura que o uso da
                Plataforma será ininterrupto ou livre de erros e você concorda
                que a Empresa poderá remover por períodos indefinidos ou
                cancelar a Plataforma a qualquer momento sem que Você seja
                avisado.
              </p>
            </li>
            <li>
              <p>
                A Empresa não garante, declara nem assegura que a Plataforma
                esteja livre de perda, interrupção, ataque, vírus,
                interferência, pirataria ou outra invasão de segurança e
                isenta-se de qualquer responsabilidade em relação a essas
                questões.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  NÓS NÃO PRESTAMOS QUALQUER GARANTIA NO SENTIDO DE QUE (I) A
                  PLATAFORMA ATENDERÁ ESPECIFICAMENTE A SUAS NECESSIDADES; OU
                  QUE (II) A PLATAFORMA IRÁ GERAR QUALQUER PROVEITO ECONÔMICO OU
                  RETORNO FINANCEIRO PARA VOCÊ.
                </strong>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <u>
              <strong>Limitação De Responsabilidade</strong>
            </u>
          </p>
        </li>
      </ol>

      <ol start={16}>
        <ol>
          <li>
            <p>
              EM NENHUMA CIRCUNSTÂNCIA, INCLUINDO, MAS NÃO SE LIMITANDO A,
              NEGLIGÊNCIA, DEVE A EMPRESA, SEUS DIRETORES, DIRETORES OU
              FUNCIONÁRIOS SER RESPONSABILIZADOS POR QUAISQUER DANOS DIRETOS,
              INDIRETOS, ESPECIAIS, INCIDENTAIS OU CONSEQUENTES DE QUALQUER
              TIPO, INCLUINDO, MAS NÃO LIMITADO A, PERDA DE USO, DADOS OU LUCROS
              CESSANTES, EM QUALQUER TEORIA DE RESPONSABILIDADE, DECORRENTE OU
              EM CONEXÃO COM O USO OU A INCAPACIDADE DE USAR A PLATAFORMA, MESMO
              QUE A EMPRESA OU UM REPRESENTANTE DA EMPRESA TENHA SIDO AVISADO DA
              POSSIBILIDADE DE TAIS DANOS. SE QUALQUER AUTORIDADE APLICÁVEL
              DETÉM QUALQUER PARTE DESTA SEÇÃO COMO INEXEQUÍVEL, ENTÃO A
              RESPONSABILIDADE SERÁ LIMITADA À MEDIDA MAIS COMPLETA PERMITIDA
              PELA LEI APLICÁVEL.
            </p>
          </li>
        </ol>
      </ol>

      <ol start={16}>
        <li>
          <p>
            <u>
              <strong>Indenização</strong>
            </u>
          </p>
        </li>
      </ol>

      <ol start={16}>
        <ol>
          <li>
            <p>
              Você concorda em indenizar, defender e manter a Empresa, seus
              diretores, funcionários e agentes, de e contra quaisquer
              reivindicações, ações ou exigências, incluindo, mas não se
              limitando a, indenizações por danos morais e materiais, alegando
              ou resultantes do uso da Plataforma ou da violação destes Termos
              de Uso para Contratantes.&nbsp;
            </p>
          </li>
        </ol>
      </ol>
      <ul>
        <li>
          <p>
            <em>
              <strong>CONSIDERAÇÕES FINAIS</strong>
            </em>
          </p>
        </li>
      </ul>
      <ol start={16}>
        <li>
          <p>
            <strong>
              <u>Alterações, Modificações e Rescisão</u>
            </strong>
          </p>
        </li>
      </ol>

      <ol start={16}>
        <ol>
          <li>
            <p>
              A Empresa reserva-se no direito de, a qualquer tempo, modificar
              este Termos de Uso para Pintores seja incluindo, removendo ou
              alterando quaisquer de suas cláusulas. Tais modificações terão
              efeito imediato. Após publicadas tais alterações, ao continuar com
              o uso da Plataforma Você terá aceitado e concordado em cumprir os
              termos modificados.&nbsp;
            </p>
          </li>
        </ol>
      </ol>

      <ol start={16}>
        <ol start={2}>
          <li>
            <p>
              A Empresa pode, de tempos em tempos, modificar ou descontinuar
              (temporária ou permanentemente) o acesso à Plataforma. A Empresa
              não é obrigada a fornecer nenhum serviço de suporte para à
              Plataforma. O Usuário não poderá responsabilizar a Empresa nem
              seus diretores, executivos, funcionários, afiliados, agentes,
              contratados ou licenciadores por quaisquer modificações,
              suspensões ou descontinuidade da Plataforma.
            </p>
          </li>
          <li>
            <p>
              A Empresa se reserva ao direito de modificar, suspender, terminar
              ou descontinuar qualquer aspecto da Plataforma, a qualquer tempo,
              no todo ou em parte, independentemente de comunicado ou aviso
              prévio. Também poderemos impor limitações a certas
              características, funcionalidades ou serviços ou restringir
              qualquer acesso a partes ou à totalidade da Plataforma, sem prévia
              notificação e sem que isso implique em qualquer responsabilidade.
            </p>
          </li>
        </ol>
      </ol>

      <ol start={16}>
        <ol start={4}>
          <li>
            <p>
              Em caso de descontinuação Plataforma e/ou de alguma de suas
              funcionalidades, os Usuários não terão direito a nenhum tipo de
              indenização.
            </p>
          </li>
        </ol>
      </ol>

      <ol start={16}>
        <ol start={5}>
          <li>
            <p>
              Quaisquer melhorias ou adições à Plataforma estarão sujeitos a
              este Termos de Uso para Pintores, a não ser que estabeleçamos de
              outra forma.&nbsp;
            </p>
          </li>
        </ol>
      </ol>

      <ol start={16}>
        <ol start={6}>
          <li>
            <p>
              Você poderá nos apresentar sugestões de modificações na
              Plataforma. As Licenciadas poderão incorporar tais modificações e
              informações adicionais a nosso exclusivo critério, nada sendo
              devido a você por tal decisão.&nbsp;
            </p>
          </li>
        </ol>
      </ol>

      <ol start={16}>
        <ol start={7}>
          <li>
            <p>
              Para a comodidade dos usuários a data da última revisão destes
              Termos de Uso para Contratantes foi incluída no topo desta página.
              Recomendamos que toda vez que acessá-los, o Usuário fique atento
              às novas atualizações, pois sua participação na Plataforma estará
              vinculada também por quaisquer alterações destes Termos de Uso
              para Pintores.
            </p>
          </li>
        </ol>
      </ol>

      <ol start={16}>
        <li>
          <p>
            <u>
              <strong>Disposições Gerais</strong>
            </u>
          </p>
        </li>
      </ol>

      <ol start={16}>
        <ol>
          <li>
            <p>
              A Empresa poderá ceder a presente relação contratual ou os
              direitos dela derivados a qualquer das empresas componentes do
              grupo econômico do qual faz parte, a seu exclusivo critério, bem
              como a quaisquer terceiros em razão de fusão, cisão, incorporação
              ou qualquer ato de reestruturação societária. &nbsp;
            </p>
          </li>
        </ol>
      </ol>

      <ol start={16}>
        <ol start={2}>
          <li>
            <p>
              A tolerância de qualquer uma das Partes, em relação a eventuais
              infrações da outra, não importará em modificação contratual,
              novação ou renúncia a direito, devendo ser considerada mera
              liberalidade da citada Parte.
            </p>
          </li>
        </ol>
      </ol>

      <ol start={16}>
        <ol start={3}>
          <li>
            <p>
              Se qualquer uma das disposições do presente Termo for ou vier a
              tornar-se nula ou revelar-se omissa, tal nulidade ou omissão não
              afetará a validade das demais disposições deste contrato. Nesse
              caso, as Partes envidarão esforços no sentido de estabelecer
              normas que mais se aproximem, quanto ao resultado, da(s)
              disposição(ões) a ser(em) alterada(s) ou eliminada(s).
            </p>
          </li>
        </ol>
      </ol>
      <ul>
        <li>
          <p>
            <em>
              <strong>DA LEGISLAÇÃO APLICÁVEL E FORO</strong>
            </em>
          </p>
        </li>
      </ul>
      <ol start={16}>
        <li>
          <p>
            Para dirimir quaisquer controvérsias oriundas do presente
            Instrumento, as partes elegem o Foro da Comarca da cidade de Taboão
            da Serra – SP, renunciando a qualquer outro por mais privilegiado
            que seja, sendo aplicáveis as Leis da República Federativa do
            Brasil.
          </p>
        </li>
      </ol>
    </>
  </Container>
);

export default TermoVisitante;
