import React from "react";
import { Avatar as AvatarMUI, Stack, Typography } from "@mui/material";
import { Player } from "video-react";

export const Avatar = ({ src = "", sx, alt = "", avatar = "", ...props }) => {
  const newSrc =
    (src || "").substring(0, 4) === "http"
      ? src
      : `${"https://api.acheseupintor.com.br"}/upload/` + src;

  return (
    <AvatarMUI
      src={src ? newSrc : avatar}
      alt={alt}
      {...props}
      sx={{ bgcolor: "primary.main", width: 56, height: 56, ...sx }}
    >
      {alt[0]}
    </AvatarMUI>
  );
};

export const Image = ({ src, sx, alt = "alt", tipo = null, ...props }) => {
  const newSrc =
    (src || "").substring(0, 4) === "http"
      ? src
      : `${"https://api.acheseupintor.com.br"}/upload/` + src;

  if (tipo === "image") {
    return (
      <img
        src={newSrc}
        alt={alt}
        style={{ ...sx, borderRadius: "10px" }}
        {...props}
      />
    );
  }
  if (tipo === "video") {
    return <Player playsInline poster="/assets/poster.png" src={newSrc} />;
  }

  if (tipo === "application") {
    return (
      <Stack sx={{ height: "100%", cursor: "pointer" }}>
        <Typography textAlign={"center"} fontSize={"12px"}>
          PDF {src}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "100%", cursor: "pointer" }}
    >
      <Typography>File {src}</Typography>
    </Stack>
  );
};
