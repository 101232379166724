import React, { useState } from "react";
import { Divider, Rating, Stack, Typography } from "@mui/material";

import { useApi } from "../../../hooks/useApi";
import { API_URL } from "../../../config/apiUrl";

import {
  Container,
  Row,
  Col,
  Avatar,
  ModalAvaliacao,
} from "../../../components";
import MenuVisitante from "./MenuVisitante";

const Historico = () => {
  const [{ data = [], loading }, refetch] = useApi({
    ...API_URL.VISITANTE_AVALIACOES,
  });

  const [avaliacao, setAvaliacao] = useState(null);
  const [open, setOpen] = useState(false);

  const onAvaliacao = (avaliacao) => {
    setOpen(true);
    setAvaliacao(avaliacao);
  };

  const onClose = () => {
    setOpen(false);
    setAvaliacao(null);
    refetch();
  };

  return (
    <Container loading={loading}>
      <MenuVisitante>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <Typography
                variant="h2"
                fontStyle="italic"
                fontWeight={700}
                textAlign={"start"}
                mr={5}
                color="primary"
              >
                HISTÓRICO DE <br /> CONTATO
              </Typography>

              <Typography
                variant="h4"
                fontStyle="italic"
                fontWeight={700}
                textAlign={"start"}
                color="secondary"
                mt={1}
                mb={1}
              >
                Acompanhe os profissionais que vocẽ entrou em contato e suas
                avaliações.
              </Typography>

              <Typography variant="body" fontStyle="italic" textAlign={"start"}>
                <strong>ATENÇÂO:</strong> as suas avaliações são super
                importantes para que os pintores possam alcançar 5 estrelas,
                pintando um universo de novas oportunidades. Não se esqueça de
                avaliar sempre que um serviço for concluido!
              </Typography>

              <Typography
                variant="body1"
                fontWeight={700}
                color="secondary"
                mt={4}
              >
                {data.length} Contatos
              </Typography>
            </Col>

            {data.map((item) => (
              <Row
                spacing={2}
                mt={2}
                key={item.id}
                onClick={
                  item.status === "AVALIADO" ? {} : onAvaliacao.bind(this, item)
                }
                sx={item.status === "AVALIADO" ? {} : { cursor: "pointer" }}
              >
                <Col>
                  <Avatar src={item.pintores.avatar} sx={styles.box} />
                </Col>
                <Col xs>
                  <Stack xs={12} direction="col" alignItems={"center"}>
                    <Typography fontWeight={700} mr={2}>
                      {item?.pintores?.nome}
                    </Typography>
                    {item.nota && (
                      <Rating value={item.nota} precision={0.5} readOnly />
                    )}
                  </Stack>
                  <Stack xs={12} mt={1}>
                    <Typography>
                      {item?.avaliacao || "Avaliação pedente."}
                    </Typography>
                  </Stack>
                </Col>
                <Col xs={12}>
                  <Divider />
                </Col>
              </Row>
            ))}
          </Row>
        </Col>
      </MenuVisitante>
      {open && (
        <ModalAvaliacao open={open} onClose={onClose} avaliacao={avaliacao} />
      )}{" "}
    </Container>
  );
};

const styles = {
  box: {
    width: "80px",
    height: "80px",
    border: "2px solid",
    borderColor: "primary.main",
    borderRadius: "100%",
    backgroundColor: "primary.main",
  },
};

export default Historico;
