import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const getOrder = () => {
  let i = 0;
  const classes = {};
  for (i === 0; i < 12; i++) {
    classes[`&-${i}`] = { order: i };
  }
  return classes;
};

const GridStyled = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.up("xs")]: {
    "&.order": getOrder(),
  },
  [theme.breakpoints.up("sm")]: {
    "&.sm-order": getOrder(),
  },
  [theme.breakpoints.up("md")]: {
    "&.md-order": getOrder(),
  },
  [theme.breakpoints.up("lg")]: {
    "&.lg-order": getOrder(),
  },
  [theme.breakpoints.up("xl")]: {
    "&.xl-order": getOrder(),
  },
}));

export const Col = ({ ...props }) => <GridStyled xs={"auto"} {...props} item />;
