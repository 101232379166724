import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import Slider from "react-slick";
import { useIsAuthenticated } from "react-auth-kit";

import { Divider, Rating, Stack, Typography, Chip } from "@mui/material/";
import { Modal, Row, Col, Avatar, Button, Image } from "../..";

import DoneIcon from "@mui/icons-material/Done";

import { useApi } from "../../../hooks/useApi";
import { API_URL } from "../../../config/apiUrl";
import { useUser } from "../../../hooks/useUser";
import { ArticleOutlined } from "@mui/icons-material";

export const ModalPintor = ({ open, onClose, pintor }) => {
  const [propaganda, setPropaganda] = useState("GALERIA");

  const onPropaganda = (newPropaganda) => {
    setPropaganda(newPropaganda);
  };

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { user } = useUser();

  const [{ data = null, loading }, fetch] = useApi(
    { ...API_URL.PINTOR_AVALIACOES_CREATE },
    { manual: true }
  );

  useEffect(() => {
    if (data) {
      window.open(
        `https://wa.me/55${
          pintor.celular ? pintor.celular.replace(/[^\d]/g, "") : ""
        }?text=+Ol%C3%A1%2C+vim+atrav%C3%A9s+do+Ache+seu+Pintor+e+preciso+de+um%0D%0Aor%C3%A7amento%21`
      );
    }
  }, [data]);

  const onOrcamento = () => {
    if (isAuthenticated()) {
      fetch({ data: { id: pintor.id } });
    }

    if (!isAuthenticated()) {
      navigate("/visitante/cadastro");
    }
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#5BD3FF",
          borderRadius: "100%",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#5BD3FF",
          borderRadius: "100%",
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <Modal open={open} onClose={onClose} maxWidth={"md"} loading={loading}>
      <Row spacing={3}>
        <Col xs={12} md={6}>
          <Row justifyContent="center" alignitems="center">
            <Col xs={12} md={4}>
              <Avatar
                src={pintor.avatar}
                alt={pintor.nome}
                sx={{
                  width: 120,
                  height: 120,
                  margin: { xs: "0 auto", md: "flex" },
                }}
              />
            </Col>
            <Col xs={12} md={8}>
              <Stack justifyContent={"center"} height="100%">
                <Typography variant="h4" fontWeight={700}>
                  {pintor.nome}
                </Typography>
                <Typography>
                  {pintor.cidade} - {pintor.estado}
                </Typography>
                <Rating name="read-only" value={pintor.notaMedia} readOnly />
                {propaganda === "GALERIA" ? (
                  <Button
                    sx={{
                      backgroundColor: "primary.main",
                      width: "140px",
                      p: 0.8,
                      mt: 1.5,
                    }}
                  >
                    <Typography
                      mt={0.5}
                      sx={{ cursor: "pointer" }}
                      variant="caption"
                      fontWeight={600}
                      color="whiter"
                      onClick={onPropaganda.bind(this, "AVALIACAO")}
                    >
                      {" "}
                      Ver avaliações
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    sx={{
                      backgroundColor: "primary.main",
                      width: "140px",
                      p: 0.8,
                      mt: 1.5,
                    }}
                  >
                    <Typography
                      mt={0.5}
                      sx={{ cursor: "pointer" }}
                      variant="caption"
                      fontWeight={600}
                      color="whiter"
                      onClick={onPropaganda.bind(this, "GALERIA")}
                    >
                      Ver galeria
                    </Typography>{" "}
                  </Button>
                )}
              </Stack>
            </Col>

            <Col xs={12} md={12} mt={2.5}>
              <Stack
                justifyContent={"space-between"}
                alignItems="space-between"
                spacing={3}
              >
                <Stack>
                  <Typography
                    variant="h5"
                    color="primary.main"
                    fontWeight={700}
                  >
                    Sobre:{" "}
                  </Typography>
                  <Typography variant="body2">{pintor.sobre}</Typography>
                </Stack>
                {(user.role === "VISITANTE" || isEmpty(user)) && (
                  <Button
                    color="primary"
                    onClick={onOrcamento.bind(this, pintor.celular)}
                  >
                    Solicitar Orçamento
                  </Button>
                )}
              </Stack>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} sx={{ height: "420px" }}>
          {propaganda === "GALERIA" && (
            <Stack spacing={1} p={1}>
              <Typography>Fotos e vídeos </Typography>
              <Stack>
                <Slider
                  {...{
                    speed: 500,
                    autoplay: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: <SampleNextArrow />,
                    prevArrow: <SamplePrevArrow />,
                  }}
                >
                  {pintor?.galeria.map((item) => (
                    <Stack sx={{ height: "334px", width: "100%" }}>
                      <Image
                        src={item.image}
                        tipo={item.tipo}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </Stack>
                  ))}
                </Slider>
              </Stack>
            </Stack>
          )}

          {propaganda === "AVALIACAO" && (
            <Stack spacing={1} p={1}>
              <Typography>{pintor?.avaliacoes?.length} Avaliações </Typography>
              <Stack sx={{ maxHeight: "344px", overflow: "auto" }}>
                {pintor?.avaliacoes.map((item) => (
                  <Row spacing={2} mb={2} key={item.id}>
                    <Col xs>
                      <Stack xs={12} direction="col" alignItems={"center"}>
                        <Typography fontWeight={700} mr={2}>
                          {item?.visitantes?.nome}
                        </Typography>
                        <Rating value={item.nota} precision={0.5} readOnly />
                      </Stack>

                      <Stack xs={12} mt={1}>
                        <Typography>
                          {item?.avaliacao || "Avaliação pedente pelo cliente."}
                        </Typography>
                      </Stack>
                    </Col>
                    <Col xs={12}>
                      <Divider />
                    </Col>
                  </Row>
                ))}
              </Stack>
            </Stack>
          )}
        </Col>
      </Row>
      <Row>
        {pintor.categorias.map((item) => (
          <Col>
            <Chip icon={<DoneIcon />} label={item?.categorias?.nome} />
          </Col>
        ))}
      </Row>
      <Row mt={3}>
        <Col xs={12}>
          <Typography variant="h5" color="primary.main" fontWeight={700}>
            Certificados
          </Typography>
        </Col>
        {pintor?.certificadosSwProCursos?.map((item) => (
          <Col>
            <Chip
              style={{ cursor: "pointer", color: "#fff" }}
              color="primary"
              onClick={() => {
                window.open(item.link);
              }}
              icon={<ArticleOutlined />}
              label={item?.nome}
            />
          </Col>
        ))}
      </Row>
    </Modal>
  );
};
