import { red } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
    h1: { fontSize: "2.4rem" },
    h2: { fontSize: "2rem" },
    h3: { fontSize: "1.8rem" },
    h4: { fontSize: "1.4rem" },
    h5: { fontSize: "1.2rem" },
    body1: { fontSize: "1rem" },
    body2: { fontSize: "0.9rem" },
    allVariants: { color: "#60646a" },
  },
  palette: {
    primary: { main: "#3cc7cf" },
    secondary: { main: "#004f99" },
    grey: "#a1a8b2",
    whiter: "#ffffff",
    gradient: {
      main: "linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
    },
    error: { main: red.A400 },
  },
  components: {
    MuiRating: {
      style: {
        color: "red",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
