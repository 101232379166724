import { useAuthUser, useSignOut, RequireAuth } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUser } from "../hooks/useUser";

export const AuthPintor = ({ children }) => {
  const auth = useAuthUser();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const usuario = auth();
  const { user } = useUser();

  useEffect(() => {
    if (usuario.role !== "PINTOR") {
      navigate("/");
      signOut();
    }
  }, [usuario]);

  useEffect(() => {
    if (user.status === "CERTIFICADO_PENDENTE") {
      navigate("/pintor/certificados");
    }
  }, [user]);

  return <RequireAuth loginPath={"/"}>{children}</RequireAuth>;
};

export const AuthVisitante = ({ children }) => {
  const auth = useAuthUser();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const usuario = auth();

  useEffect(() => {
    if (usuario.role !== "VISITANTE") {
      navigate("/");
      signOut();
    }
  }, [usuario]);

  return <RequireAuth loginPath={"/"}>{children}</RequireAuth>;
};
