import React from "react";
import { useNavigate } from "react-router-dom";
import { useSignOut } from "react-auth-kit";

import { Stack, Typography } from "@mui/material";
import {
  SwitchAccount,
  CameraAlt,
  Help,
  AddLocationAlt,
  Contacts,
  WhatsApp,
  MailOutline,
} from "@mui/icons-material";

import { Row, Col, Button } from "../../../components";
import { useUser } from "../../../hooks/useUser";

const MenuPintor = ({ children, subMenu }) => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const { saveUser, user } = useUser();

  const onSingOut = () => {
    navigate("/");
    signOut();
    saveUser(null);
  };

  return (
    <Row
      justifyContent="space-beteween"
      alignItem="center"
      spacing={2}
      mb={2}
      mt={1}
    >
      <Col xs={12} md={5} sx={{ padding: "1rem" }}>
        <Row
          spacing={2}
          sx={{
            ...styles.box,
            height: user.status === "CERTIFICADO_PENDENTE" ? "200px" : "610px",
          }}
        >
          {user.status !== "CERTIFICADO_PENDENTE" &&
            [
              {
                title: "Meu cadastro",
                subtitle: "Veja os dados do seu cadastro",
                url: "/pintor/perfil",
                icon: <SwitchAccount fontSize="large" color="secondary" />,
              },
              {
                title: "Galeria",
                subtitle: "Inclua fotos e videos no seu perfil",
                url: "/pintor/galeria",
                icon: <CameraAlt fontSize="large" color="secondary" />,
              },
              {
                title: "Dúvidas frequentes",
                subtitle: "Tire suas dúvidas",
                url: "/pintor/duvidas",
                icon: <Help fontSize="large" color="secondary" />,
              },
              {
                title: "Minha região",
                subtitle: "Fique por dentro das pesquisas na sua área",
                url: "/pintor/regiao",
                icon: <AddLocationAlt fontSize="large" color="secondary" />,
              },
              {
                title: "Contatos e avaliações",
                subtitle: "Clientes e avaliações",
                url: "/pintor/avaliacoes",
                icon: <Contacts fontSize="large" color="secondary" />,
              },
            ].map((item) => (
              <Col
                xs={12}
                sx={{ cursor: "pointer" }}
                onClick={navigate.bind(this, item.url)}
              >
                <Stack direction={"row"} fullWidth spacing={2}>
                  <Stack>
                    <Stack
                      sx={styles.boxImg}
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      {item.icon}
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack>
                      <Typography
                        variant="body1"
                        fontWeight={700}
                        fontStyle={"italic"}
                      >
                        {item.title}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        variant="body1"
                        fontSize={14}
                        fontStyle={"italic"}
                      >
                        {item.subtitle}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Col>
            ))}
          <Col xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onSingOut}
            >
              Sair
            </Button>
          </Col>

          <Col xs={12}>
            <Stack
              justifyContent={"space-around"}
              alignItems="space-around"
              direction={"row"}
              spacing={1}
            >
              <Stack
                justifyContent={"center"}
                alignItems="center"
                spacing={0.7}
                sx={{ cursor: "pointer" }}
              >
                <WhatsApp fontSize="large" color="secondary" />
                <Typography variant="body1" fontSize={14} fontStyle={"italic"}>
                  WhatsApp{" "}
                </Typography>
              </Stack>{" "}
              <Stack
                justifyContent={"center"}
                alignItems="center"
                spacing={0.7}
                sx={{ cursor: "pointer" }}
              >
                <MailOutline fontSize="large" color="secondary" />
                <Typography variant="body1" fontSize={14} fontStyle={"italic"}>
                  Contato
                </Typography>
              </Stack>
            </Stack>
          </Col>
        </Row>

        {subMenu && (
          <Row spacing={2} sx={{ ...styles.box }} mt={2}>
            <Col xs={12}>{subMenu}</Col>
          </Row>
        )}
      </Col>
      <Col xs={12} md={7}>
        <Row
          spacing={2}
          sx={{ minHeight: subMenu ? "" : "100%", ...styles.box }}
        >
          {children}
        </Row>
      </Col>
    </Row>
  );
};

const styles = {
  box: {
    background: "#fff",
    borderRadius: "22px",
    padding: "1rem",
    justifyContent: "start",
    alignItem: "start",
  },
  boxCadastro: {
    background: "#f2f2f2",
    padding: "2rem",
    borderRadius: "30px",
  },
  boxImg: {
    width: "70px",
    height: "70px",
    border: "double 2px transparent",
    borderRadius: "100%",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
  },
};

export default MenuPintor;
