import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { isEmpty } from "lodash";
import moment from "moment";
import { Stack, Typography } from "@mui/material";
import { CheckBoxOutlined, Lock } from "@mui/icons-material";

import {
  Container,
  Col,
  Button,
  Input,
  InputMask,
  Row,
  ModalMidia,
  Avatar,
} from "../../../components";
import MenuPintor from "./MenuPintor";

import { useApi } from "../../../hooks/useApi";
import { useUser } from "../../../hooks/useUser";

import { API_URL } from "../../../config/apiUrl";
import api from "../../../services/api";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/system";

const PintorPerfiel = () => {
  const { saveUser, user, updateMe } = useUser();
  const [loadingCep, setLoadingCep] = useState(false);
  // const [certificados, setCertificados] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [avatarImg, setAvatar] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(false);

  useEffect(() => {
    if (!isEmpty(user?.categorias)) {
      setCategorias(user?.categorias?.map((v) => v.categoriaId));
    }
  }, [user]);

  const onChangeCategoria = (e) => {
    if (e.target.checked) {
      setCategorias([...categorias, Number(e.target.value)]);
    }

    if (!e.target.checked) {
      const i = categorias.indexOf(Number(e.target.value));
      let temp = [...categorias];
      delete temp[i];
      temp = temp.filter((i) => i);
      setCategorias([...temp]);
    }
  };

  const [{ loading: loadingDelete, data: dataDelete }, deleteRequest] = useApi(
    { ...API_URL.PINTOR_CERTIFICADO_DELETE },
    { manual: true }
  );

  const [{ loading: loadingCertificados, data: dataCertificados }] = useApi(
    { ...API_URL.PINTOR_SW_PRO },
    { manual: true }
  );

  const [{ data, loading }, fetch] = useApi(
    { ...API_URL.PINTOR_UPDATE },
    { manual: true }
  );

  const [{ data: servicos = [] }] = useApi({ ...API_URL.SERVICOS });

  // const onMidia = (item) => {
  //   setOpen(true);
  //   setSelectedImage(item);
  // };

  const onClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    if (dataDelete) {
      setSelectedImage(null);
      setOpen(false);
      updateMe();
    }

    if (dataCertificados) {
      updateMe();
    }
  }, [dataDelete, dataCertificados]);

  useEffect(() => {
    if (data) {
      saveUser(data?.usuario);
    }
  }, [data]);

  const onCep = async (setFieldValue, e) => {
    setLoadingCep(true);

    try {
      const cep = e.target.value.replace(/^\d{4}$/, "");
      const { data } = await api.get(`/cep?cep=${cep}`);

      setFieldValue("rua", data.endereco, true);
      setFieldValue("bairro", data.bairro, true);
      setFieldValue("cidade", data.cidade, true);
      setFieldValue("estado", data.uf, true);
    } catch (error) {}

    setLoadingCep(false);
  };

  const onSubmit = (values) => {
    fetch({ data: { ...values, categorias } });
  };

  // const onCertificados = (e) => {
  //   setCertificados(e.target.files);
  // };

  const onDelete = () => {
    deleteRequest({
      data: { id: selectedImage.id, filename: selectedImage.documento },
    });
  };

  // const onSubmitCertificados = () => {
  //   const formData = new FormData();

  //   for (const item of certificados) {
  //     formData.append("certificados", item);
  //   }

  //   certificadoRequest({ data: formData });
  //   setCertificados([]);
  // };
  const dataNascimento = moment(user.dataNascimento)
    .add(1, "days")
    .format("DD/MM/YYYY");

  const onSelectAvatar = (e) => {
    setAvatar(e.target.files[0]);
    setAvatarUrl(URL.createObjectURL(e.target.files[0] ?? ""));
  };

  const onCleanAvatar = () => {
    setAvatar(false);
    setAvatarUrl(false);
  };

  const onAvatar = async () => {
    setLoadingCep(true);
    try {
      const formData = new FormData();
      formData.append("avatar", avatarImg);

      const { data: avatar } = await api({
        ...API_URL.PINTOR_AVATAR,
        data: formData,
      });

      saveUser(avatar.usuario);
      onCleanAvatar();
    } catch (error) {}

    setLoadingCep(false);
  };

  return (
    <Container
      loading={loadingCep || loading || loadingDelete || loadingCertificados}
    >
      <MenuPintor
        subMenu={
          <Col xs={12} sx={styles.box}>
            <Formik
              initialValues={user}
              onSubmit={(values) => {
                onSubmit(values);
              }}
              enableReinitialize
            >
              {() => (
                <Form>
                  <Row spacing={2} mt={{ md: 1, xs: 0 }} mb={6}>
                    <Col xs={12} md={12}>
                      <Stack direction={"row"} spacing={2} alignItems="center">
                        <Lock color="secondary" fontSize="large" />
                        <Typography
                          variant="h5"
                          fontWeight={700}
                          color={"secondary"}
                        >
                          Crie sua senha contendo 8-20 caracteres incluindo
                          letras e números.
                        </Typography>
                      </Stack>
                    </Col>

                    <Col xs={12} md={12}>
                      <Field
                        as={Input}
                        name="senha"
                        type="password"
                        placeholder="Senha*"
                        fullWidth
                        required
                        size="large"
                      />
                    </Col>

                    <Col xs={12} md={12}>
                      <Field
                        as={Input}
                        name="confirmSenha"
                        type="password"
                        placeholder="Confirmar Senha Senha*"
                        fullWidth
                        required
                        size="large"
                      />
                    </Col>

                    <Col xs={12} md={12} justifyContent="end">
                      <Button color="primary" fullWidth type="submit">
                        Atualizar
                      </Button>{" "}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        }
      >
        <Col xs={12} container>
          <Col xs={12}>
            <Typography variant="h3" fontWeight={700} fontStyle={"italic"}>
              MEU CADASTRO
            </Typography>
            <Typography
              mt={"0.1rem"}
              fontStyle={"italic"}
              fontWeight={500}
              color="secondary"
            >
              Mantenha sempre atualizado os seus dados.
            </Typography>
          </Col>

          <Col xs={12}>
            <Stack
              justifyContent="center"
              alignItems="center"
              mt={2}
              component="label"
              sx={{ cursor: "pointer" }}
            >
              {!avatarUrl && (
                <Avatar
                  alt={user.nome}
                  src={user.avatar}
                  sx={{ width: 90, height: 90, cursor: "pointer" }}
                />
              )}

              {avatarUrl && (
                <Avatar
                  alt={user.nome}
                  avatar={avatarUrl}
                  sx={{ width: 90, height: 90 }}
                />
              )}

              <input
                type="file"
                accept="image/*"
                hidden
                required
                onChange={onSelectAvatar}
              />
            </Stack>
            {avatarUrl && (
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems="center"
                mt={2}
                spacing={2}
              >
                <Button color="primary" onClick={onAvatar}>
                  Salvar Foto
                </Button>
                <Button onClick={onCleanAvatar}>Limpar Foto</Button>{" "}
              </Stack>
            )}
          </Col>

          <Formik
            initialValues={{
              ...user,
              dataNascimento,
            }}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            enableReinitialize
          >
            {({ setFieldValue }) => (
              <Form>
                <Col xs={12} container spacing={1} mt={2} p={1}>
                  <Col xs={12} md={7}>
                    <Field
                      as={Input}
                      name="nome"
                      color="cinza"
                      placeholder="Nome completo"
                      fullWidth
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={5}>
                    <Field
                      as={InputMask}
                      mask={"999.999.999-99"}
                      name="cpf"
                      color="cinza"
                      placeholder="CPF"
                      fullWidth
                      size="large"
                    />
                  </Col>{" "}
                  <Col xs={12} md={4}>
                    <Field
                      as={InputMask}
                      mask="99/99/9999"
                      name="dataNascimento"
                      placeholder="Data de nascimento *"
                      fullWidth
                      size="large"
                      color="cinza"
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      as={Input}
                      name="email"
                      placeholder="E-mail *"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={InputMask}
                      mask="(99) 99999-9999"
                      name="celular"
                      placeholder="Celular * "
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="instagram"
                      placeholder="Instagram"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      as={InputMask}
                      name="cep"
                      mask={"99999-999"}
                      placeholder="CEP"
                      fullWidth
                      size="large"
                      color="cinza"
                      onBlur={onCep.bind(this, setFieldValue)}
                    />
                  </Col>
                  <Col xs={12} md={9}>
                    <Field
                      as={Input}
                      name="rua"
                      placeholder="Endereço*"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <Field
                      as={Input}
                      name="numero"
                      placeholder="Número*"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="bairro"
                      placeholder="Bairro*"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={InputMask}
                      name="complemento"
                      placeholder="Complemento"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="cidade"
                      placeholder="Cidade*"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="estado"
                      placeholder="Estado"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      as={Input}
                      name="sobre"
                      placeholder="Sobre"
                      multiline
                      rows={4}
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} mt={1}>
                    <Button color="primary" fullWidth type="submit">
                      Atualizar
                    </Button>
                  </Col>
                </Col>
              </Form>
            )}
          </Formik>
        </Col>
      </MenuPintor>

      <Row spacing={2} mt={2}>
        {/*  <Col xs={12} md={7} p={3}>
           <Stack>
            <Typography variant="h3" fontWeight={700} fontStyle={"italic"}>
              Meus certificados
            </Typography>
            <Typography
              mt={"0.1rem"}
              fontStyle={"italic"}
              fontWeight={700}
              color="secondary"
            >
              Anexe aqui seus certificados SWPRO Cursos{" "}
            </Typography>
          </Stack>
          <Stack my={3} spacing={1}>
            {!isEmpty(user.certificados) &&
              user?.certificados?.map((item) => (
                <Stack
                  p={0.5}
                  onClick={onMidia.bind(this, item)}
                  sx={{
                    border: "1px solid blue",
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                >
                  <Image
                    src={item.documento}
                    tipo={item.tipo}
                    style={{
                      maxWidth: "90%",
                      maxHeight: "110px",
                      margin: "0 auto",
                    }}
                  />
                </Stack>
              ))}
            {isEmpty(user.certificados) && (
              <Typography
                variant="h3"
                fontStyle="italic"
                fontWeight={700}
                textAlign={"center"}
                mr={5}
              >
                SWPRO CURSOS <br /> VAZIO
              </Typography>
            )}
          </Stack>

          <Stack>
            {isEmpty(certificados) && (
              <Button color="primary" component="label">
                {isEmpty(certificados)
                  ? "Selecione arquivos"
                  : `${certificados.length} arquivos selecionados`}
                <input type="file" hidden multiple onChange={onCertificados} />
              </Button>
            )}

            {!isEmpty(certificados) && (
              <ButtonMU
                color="success"
                type="submit"
                fullWidth
                variant="outlined"
                onClick={onSubmitCertificados}
              >
                Enviar {certificados.length} certificados
              </ButtonMU>
            )}
          </Stack> 
        </Col>*/}
      </Row>

      <Row mt={3}>
        <Col xs={12} p={4} sx={styles.box}>
          <Stack direction={"row"} spacing={2} alignItems="center">
            <CheckBoxOutlined color="secondary" fontSize="large" />
            <Typography variant="h5" fontWeight={700} color={"secondary"}>
              Em quais categorias de pintura você tem experiência e expertise?
            </Typography>
          </Stack>
          <Formik
            initialValues={user}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            enableReinitialize
          >
            {() => (
              <Form>
                <Row
                  mt={2}
                  sx={{
                    width: "100%",
                    background: "#fff",
                    p: "0.8rem",
                    borderRadius: "1rem",
                  }}
                >
                  {servicos.map((e) => (
                    <Col xs={12} sm={6} md={4} key={e.id}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={categorias?.includes(e.id)} />
                        }
                        label={e.nome}
                        value={e.id}
                        onChange={onChangeCategoria}
                      />
                    </Col>
                  ))}
                </Row>{" "}
                <Stack alignItems="end">
                  <Button color="primary" type="submit" sx={{ width: "120px" }}>
                    Atualizar
                  </Button>{" "}
                </Stack>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>

      {open && (
        <ModalMidia
          open={open}
          onClose={onClose}
          midia={selectedImage}
          onDelete={onDelete}
        />
      )}
    </Container>
  );
};

const styles = {
  box: {
    background: "#fff",
    borderRadius: "22px",
    justifyContent: "start",
    alignItem: "start",
  },
};

export default PintorPerfiel;
