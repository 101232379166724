import React, { useEffect, useState } from "react";

import { Field, Form, Formik } from "formik";
import { Stack, Typography } from "@mui/material";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

import { Row } from "./Row";
import { Col } from "./Col";
import { Button } from "./Button";
import { Input, InputMask } from "./Inputs";
import { Modal } from "./Modal";

import { useApi } from "../hooks/useApi";
import { API_URL } from "../config/apiUrl";
import { useUser } from "../hooks/useUser";

import api from "../services/api";
import FacebookLogin from "react-facebook-login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLogin from "./GoogleLogin";

export const ModalLogin = ({ open, onClose }) => {
  const [isLoading, setLoading] = useState(false);

  const [role, setRole] = useState("VISITANTE");
  const [page, setPage] = useState("LOGIN");
  const { saveUser } = useUser();

  const navigate = useNavigate();
  const signIn = useSignIn();

  const onPage = (newPage) => {
    setPage(newPage);
  };

  const [{ data, loading }, fetch] = useApi(
    { ...API_URL.LOGIN },
    { manual: true }
  );

  const onRole = (item) => {
    setRole(item);
  };

  useEffect(() => {
    setPage("LOGIN");
  }, [open]);

  useEffect(() => {
    if (data) {
      signIn({
        token: data.access_token,
        expiresIn: data.expiresIn,
        authState: data.usuario,
        tokenType: "Bearer",
      });

      saveUser(data.usuario);

      onClose();

      if (data?.usuario?.status === "CERTIFICADO_PENDENTE") {
        navigate("/pintor/certificados");
        return;
      }

      if (role === "VISITANTE") {
        navigate("/visitante/pintor");
      }

      if (role === "PINTOR") {
        navigate("/pintor/galeria");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = (values) => {
    console.log("onSubmit", values);

    fetch({ data: { ...values, role } });
  };

  const loginFacebook = async (e) => {
    const login = await api({
      ...API_URL.LOGIN_SOCIAL,
      data: { facebookToken: e.accessToken, role: "VISITANTE" },
    });

    if (!login.data.usuario && login.data.social) {
      onClose();
      return navigate(
        `/visitante/cadastro?facebookId=${login.data.social.user_id}&nome=${login.data.social.name}&email=${login.data.social.email}`
      );
    }

    console.log(`responseFacebook`, e, login.data.usuario);

    onSubmit({
      cpf: login.data.usuario.cpf,
      senha: login.data.usuario.hashSenha,
    });
  };

  const loginGoogle = async (e) => {
    const login = await api({
      ...API_URL.LOGIN_SOCIAL,
      data: { googleOauth: e.access_token, role: "VISITANTE" },
    });
    console.log(`loginGoogle`, e, login);

    if (!login.data.usuario && login.data.social) {
      onClose();
      return navigate(
        `/visitante/cadastro?googleAuthId=${login.data.social.sub}&nome=${login.data.social.name}&email=${login.data.social.email}`
      );
    }

    onSubmit({
      cpf: login.data.usuario.cpf,
      senha: login.data.usuario.hashSenha,
    });
  };

  const onSenha = async (values) => {
    setLoading(true);
    try {
      await api({
        ...API_URL.RESET_SENHA,
        data: { ...values, role },
      });

      onPage("SENHA_RESET");
    } catch (error) {}

    setLoading(false);
  };

  const onNovaSenha = async (values) => {
    setLoading(true);
    try {
      const { data: dataSenha } = await api({
        ...API_URL.NOVA_SENHA,
        data: { ...values, role },
      });

      signIn({
        token: dataSenha.access_token,
        expiresIn: dataSenha.expiresIn,
        authState: dataSenha.usuario,
        tokenType: "Bearer",
      });

      saveUser(dataSenha.usuario);

      onClose();

      if (dataSenha?.usuario?.status === "CERTIFICADO_PENDENTE") {
        navigate("/pintor/certificados");
      }

      if (role === "VISITANTE") {
        navigate("/visitante/pintor");
      }

      if (role === "PINTOR") {
        navigate("/pintor/galeria");
      }
    } catch (error) {}

    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={"Faça seu Login"}
      maxWidth="xs"
      loading={!!loading || !!isLoading}
    >
      {/*{page !== "SENHA_RESET" && (*/}
      {/*  <Row spacing={2}>*/}
      {/*    <Col xs={12} container spacing={2} mb={1}>*/}
      {/*      <Col xs={6}>*/}
      {/*        {role === "VISITANTE" ? (*/}
      {/*          <Button*/}
      {/*            color="primary"*/}
      {/*            fullWidth*/}
      {/*            onClick={onRole.bind(this, "VISITANTE")}*/}
      {/*          >*/}
      {/*            Cliente*/}
      {/*          </Button>*/}
      {/*        ) : (*/}
      {/*          <Button fullWidth onClick={onRole.bind(this, "VISITANTE")}>*/}
      {/*            Cliente*/}
      {/*          </Button>*/}
      {/*        )}*/}
      {/*      </Col>*/}
      {/*      <Col xs={6}>*/}
      {/*        {role === "PINTOR" ? (*/}
      {/*          <Button*/}
      {/*            color="primary"*/}
      {/*            fullWidth*/}
      {/*            onClick={onRole.bind(this, "PINTOR")}*/}
      {/*          >*/}
      {/*            Pintor*/}
      {/*          </Button>*/}
      {/*        ) : (*/}
      {/*          <Button fullWidth onClick={onRole.bind(this, "PINTOR")}>*/}
      {/*            Pintor*/}
      {/*          </Button>*/}
      {/*        )}*/}
      {/*      </Col>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*)}*/}
      {page === "LOGIN" && (
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {() => (
            <Form>
              <FacebookLogin
                appId="571176977891327"
                fields="name,email,picture"
                icon="fa-facebook"
                textButton="Login com Facebook"
                cssClass="btn-primario btn-primario2"
                callback={loginFacebook}
              ></FacebookLogin>

              <div style={{ paddingTop: "8px" }}>
                <GoogleOAuthProvider clientId="425931863053-i3tf8n6mrujvl5slcs7pr4ql48po0eg8.apps.googleusercontent.com">
                  <GoogleLogin onLogin={loginGoogle}></GoogleLogin>
                </GoogleOAuthProvider>
              </div>

              <p style={{ textAlign: "center" }}>ou</p>

              <Row spacing={2} mt={1}>
                <Col xs={12}>
                  <Field
                    as={InputMask}
                    mask="999.999.999-99"
                    name="cpf"
                    placeholder="CPF"
                    label="CPF"
                    color="cinza"
                    fullWidth
                    size="large"
                    required
                  />
                </Col>

                <Col xs={12}>
                  <Field
                    as={Input}
                    name="senha"
                    placeholder="Senha"
                    type="password"
                    label="Senha"
                    color="cinza"
                    fullWidth
                    size="large"
                    required
                  />
                </Col>

                <Col xs={12}>
                  <Stack
                    alignItems={"end"}
                    my={1}
                    onClick={onPage.bind(this, "SENHA")}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography variant="text">Esqueceu sua senha?</Typography>{" "}
                  </Stack>
                </Col>

                <Col xs={12}>
                  <Button color="primary" type="submit" fullWidth>
                    Login
                  </Button>
                  <Stack direction={"row"} mt={2}>
                    <Stack justifyContent="center"></Stack>
                  </Stack>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
      {page === "SENHA" && (
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            onSenha(values);
          }}
        >
          {() => (
            <Form>
              <Row spacing={2} mt={1}>
                <Col xs={12}>
                  {" "}
                  <Typography variant="text">
                    Digite seu CPF e receba no seu email seu codigo.
                  </Typography>{" "}
                </Col>
                <Col xs={12}>
                  <Field
                    as={InputMask}
                    mask="999.999.999-99"
                    name="cpf"
                    placeholder="CPF"
                    label="CPF"
                    color="cinza"
                    fullWidth
                    size="large"
                  />
                </Col>

                <Col xs={12}>
                  <Button color="primary" type="submit" fullWidth>
                    RESETAR SENHA
                  </Button>
                  <Stack direction={"row"} mt={2}>
                    <Stack justifyContent="center"></Stack>
                  </Stack>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
      {page === "SENHA_RESET" && (
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            onNovaSenha(values);
          }}
        >
          {() => (
            <Form>
              <Row spacing={2} mt={1}>
                <Col xs={12}>
                  <Typography variant="text">
                    Digite seu codigo e sua senha nova senha.
                  </Typography>
                </Col>
                <Col xs={12}>
                  <Field
                    as={Input}
                    name="codigo"
                    placeholder="Codigo"
                    label="Codigo"
                    color="cinza"
                    fullWidth
                    size="large"
                  />
                </Col>

                <Col xs={12}>
                  <Field
                    as={Input}
                    name="senha"
                    placeholder="Nova Senha"
                    type="password"
                    label="Nova Senha"
                    color="cinza"
                    fullWidth
                    size="large"
                  />
                </Col>

                <Col xs={12}>
                  <Button color="primary" type="submit" fullWidth>
                    Redefinir Senha
                  </Button>
                  <Stack direction={"row"} mt={2}>
                    <Stack justifyContent="center"></Stack>
                  </Stack>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
