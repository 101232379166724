import { Button as ButtonMU } from "@mui/material";

const getStyle = (color) => {
  const defaultHover = {
    transform: "rotate(850deg)",
    // eslint-disable-next-line no-dupe-keys
    transform: "scale(1.07)",
  };

  const defaultCss = {
    "&.Mui-disabled": {
      background: "#E0E0E0",
    },
  };

  if (color === "default") {
    return {
      backgroundColor: "#373A36",
      color: "#fff",
      border: "0px",
      "&:hover": {
        border: "0px",
        backgroundColor: "#373A36",
        ...defaultHover,
      },
      ...defaultCss,
    };
  }

  if (color === "primary") {
    return {
      background:
        "linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
      color: "#fff",
      border: "0px",
      "&:hover": {
        border: "0px",
        ...defaultHover,
      },
      ...defaultCss,
    };
  }
};

export const Button = ({
  children,
  color = "default",
  variant,
  sx,
  ...props
}) => {
  const styles = variant ? {} : getStyle(color);

  return (
    <ButtonMU
      {...props}
      sx={{
        borderRadius: "0.5rem",
        ...styles,
        ...sx,
      }}
    >
      {children}
    </ButtonMU>
  );
};
