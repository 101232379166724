import React from "react";
import { Divider, Rating, Stack, Typography } from "@mui/material";

import { useApi } from "../../../hooks/useApi";
import { API_URL } from "../../../config/apiUrl";

import { Container, Row, Col, Avatar } from "../../../components";
import MenuPintor from "./MenuPintor";

const Avaliacoes = () => {
  const [{ data = [], loading }] = useApi({
    ...API_URL.PINTOR_AVALIACOES,
  });

  return (
    <Container loading={loading}>
      <MenuPintor>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <Typography
                variant="h2"
                fontStyle="italic"
                fontWeight={700}
                textAlign={"start"}
                mr={5}
              >
                CONTATO E <br /> AVALIAÇÔES{" "}
              </Typography>

              <Typography
                variant="body1"
                fontWeight={700}
                color="secondary"
                mt={4}
              >
                {data.length} Contatos
              </Typography>
            </Col>

            {data.map((item) => (
              <Row spacing={2} mt={2} key={item.id}>
                <Col xs>
                  <Stack xs={12} direction="col" alignItems={"center"}>
                    <Typography fontWeight={700} mr={2}>
                      {item?.visitantes?.nome}
                    </Typography>
                    {item.nota && (
                      <Rating value={item.nota} precision={0.5} readOnly />
                    )}
                  </Stack>
                  <Stack xs={12} mt={1}>
                    <Typography>
                      {item?.avaliacao || "Avaliação pedente pelo cliente."}
                    </Typography>
                  </Stack>
                </Col>
                <Col xs={12}>
                  <Divider />
                </Col>
              </Row>
            ))}
          </Row>
        </Col>
      </MenuPintor>
    </Container>
  );
};

const styles = {
  box: {
    width: "80px",
    height: "80px",
    border: "2px solid",
    borderColor: "primary.main",
    borderRadius: "100%",
    backgroundColor: "primary.main",
    opacity: 0.5,
  },
};

export default Avaliacoes;
