import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";

import { Drawer, Stack, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MenuOutlined } from "@mui/icons-material";

import { Row } from "./Row";
import { Col } from "./Col";
import { Container } from "./Container";
import { ModalLogin } from "./ModalLogin";
import { ModalTutorial } from "./ModalTutorial";

import logo from "../assets/logo.png";

export const Header = () => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [openTutorial, setOpenTutorial] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const url = useLocation().pathname;
  const auth = useAuthUser();
  const role = auth()?.role;

  const onMobile = () => setOpenMobile(!openMobile);

  const handleLogin = () => {
    setOpen(!open);
    if (isMobile) setOpenMobile(false);
  };

  const onNavigate = (to) => {
    navigate(to);
    if (isMobile) setOpenMobile(false);
  };

  const onTutorial = () => {
    setOpenTutorial(!openTutorial);
    if (isMobile) setOpenMobile(false);
  };

  const menus = [
    // { nome: "Cadastre-se pintor", url: "/cadastro-pintor" }
  ];

  return (
    <Container>
      <Row
        my={5}
        justifyContent={{ md: "space-between", sm: "space-around" }}
        alignItems="center"
      >
        <Col justifyContent="center" alignItems="center">
          <BoxLogo onClick={onNavigate.bind(this, "/")}>
            <img src={logo} alt="logo" width={isMobile ? "100px" : "240px"} />
          </BoxLogo>
        </Col>
        {isMobile ? (
          <Col>
            <MenuOutlined fontSize="large" onClick={onMobile} />
          </Col>
        ) : (
          <Col>
            <Col container spacing={2}>
              <Col xs={12} md="auto">
                <Menu
                  onClick={onNavigate.bind(this, "/")}
                  url={url}
                  p={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <a
                    href="#cep"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {" "}
                    Ache seu pintor{" "}
                  </a>
                </Menu>{" "}
              </Col>
              {menus.map((item) => (
                <Col
                  onClick={item.onClick || onNavigate.bind(this, item.url)}
                  key={item.nome}
                  xs={12}
                  md="auto"
                >
                  <Menu
                    item={item.url}
                    url={url}
                    p={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {item.nome}
                  </Menu>{" "}
                </Col>
              ))}

              <Col onClick={onTutorial} xs={12} md="auto">
                <Menu
                  url={url}
                  p={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  Tutorial{" "}
                </Menu>{" "}
              </Col>
              {isAuthenticated() ? (
                <>
                  <Col
                    onClick={onNavigate.bind(
                      this,
                      role === "PINTOR"
                        ? "/pintor/galeria"
                        : "/visitante/pintor"
                    )}
                    key={"login"}
                    xs={12}
                    md="auto"
                  >
                    <Menu
                      item={
                        role === "PINTOR"
                          ? "/pintor/galeria"
                          : "/visitante/pintor"
                      }
                      url={url}
                      p={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      Perfil{" "}
                    </Menu>
                  </Col>
                </>
              ) : (
                <>
                  <Col onClick={handleLogin} key={"login"} xs={12} md="auto">
                    <Menu
                      url={url}
                      p={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      Sou cliente
                    </Menu>
                  </Col>
                  <Col
                    onClick={() => {
                      window.open(
                        "https://www.clubepropintor.com.br",
                        "_blank"
                      );
                    }}
                    key={"login"}
                    xs={12}
                    md="auto"
                  >
                    <Menu
                      url={url}
                      p={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      Sou pintor
                    </Menu>
                  </Col>
                </>
              )}
            </Col>
          </Col>
        )}
      </Row>
      <ModalLogin open={open} onClose={handleLogin} />
      <ModalTutorial open={openTutorial} onClose={onTutorial} />
      <Drawer open={openMobile} onClose={onMobile}>
        <Col mt={2} p={1}>
          <Col container spacing={2}>
            {menus.map((item) => (
              <Col
                onClick={item.onClick || onNavigate.bind(this, item.url)}
                key={item.nome}
                xs={12}
                md="auto"
              >
                <Menu
                  item={item.url}
                  url={url}
                  p={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  {item.nome}
                </Menu>{" "}
              </Col>
            ))}
            {isAuthenticated() ? (
              <Col
                onClick={onNavigate.bind(
                  this,
                  role === "PINTOR" ? "/pintor/galeria" : "/visitante/perfil"
                )}
                key={"login"}
                xs={12}
                md="auto"
              >
                <Menu
                  item={
                    role === "PINTOR" ? "/pintor/galeria" : "/visitante/perfil"
                  }
                  url={url}
                  p={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  Perfil{" "}
                </Menu>
              </Col>
            ) : (
              <Col onClick={handleLogin} key={"login"} xs={12} md="auto">
                <Menu
                  url={url}
                  p={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  Sou cliente{" "}
                </Menu>
              </Col>
            )}
          </Col>
        </Col>
      </Drawer>
    </Container>
  );
};

const BoxLogo = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    top: "20px",
    zIndex: 20,
    position: "absolute",
    cursor: "pointer",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Menu = styled(Stack)(({ theme, item, url }) => ({
  borderRadius: "0.8rem",
  minWidth: "80px",
  cursor: "pointer",
  border: item === url ? "solid 2px #3cc7cf" : " ",
  fontWeight: item === url ? 700 : " ",
}));
