import React, { useEffect } from "react";

import { Field, Form, Formik } from "formik";
import { Rating, Stack } from "@mui/material";

import { Row } from "./Row";
import { Col } from "./Col";
import { Button } from "./Button";
import { Input } from "./Inputs";
import { Modal } from "./Modal";

import { useApi } from "../hooks/useApi";
import { API_URL } from "../config/apiUrl";

export const ModalAvaliacao = ({ open, onClose, avaliacao }) => {
  const [{ data, loading }, fetch] = useApi(
    { ...API_URL.VISITANTE_AVALIACOES_UPDATE },
    { manual: true }
  );

  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data]);

  const onSubmit = (values) => {
    fetch({ data: { id: avaliacao.id, ...values } });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`Deixe sua avaliação para ${avaliacao?.pintores?.nome}`}
      maxWidth="xs"
      loading={loading}
    >
      <Formik
        initialValues={{}}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {() => (
          <Form>
            <Row spacing={2}>
              <Col xs={12}>
                <Field as={Rating} name="nota" size="large" />
              </Col>

              <Col xs={12}>
                <Field
                  as={Input}
                  name="avaliacao"
                  placeholder="Deixe sua avalição"
                  label="Deixe sua avalição"
                  rows={4}
                  multiline
                  color="cinza"
                  fullWidth
                  size="large"
                />
              </Col>

              <Col xs={12}>
                <Button color="primary" type="submit" fullWidth>
                  Avaliar
                </Button>
                <Stack direction={"row"} mt={2}>
                  <Stack justifyContent="center"></Stack>
                </Stack>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
