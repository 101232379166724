import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";

import { Typography, Stack } from "@mui/material";

import {
  Container,
  Col,
  Button,
  Input,
  InputMask,
  Row,
} from "../../../components";

import { useApi } from "../../../hooks/useApi";
import { useUser } from "../../../hooks/useUser";

import { API_URL } from "../../../config/apiUrl";
import api from "../../../services/api";
import MenuVisitante from "./MenuVisitante";
import { Lock } from "@mui/icons-material";

const VisitantePerfil = () => {
  const { saveUser, user } = useUser();

  const [loadingCep, setLoadingCep] = useState(false);

  const [{ data, loading }, fetch] = useApi(
    { ...API_URL.VISITANTE_UPDATE },
    { manual: true }
  );

  useEffect(() => {
    if (data) {
      saveUser(data?.usuario);
    }
  }, [data]);

  const onCep = async (setFieldValue, e) => {
    setLoadingCep(true);

    try {
      const cep = e.target.value.replace(/^\d{4}$/, "");
      const { data } = await api.get(`/cep?cep=${cep}`);

      setFieldValue("rua", data.endereco, true);
      setFieldValue("bairro", data.bairro, true);
      setFieldValue("cidade", data.cidade, true);
      setFieldValue("estado", data.uf, true);
    } catch (error) {}

    setLoadingCep(false);
  };

  const onSubmit = (values) => {
    fetch({ data: values });
  };

  return (
    <Container loading={loadingCep || loading}>
      <MenuVisitante
        subMenu={
          <Formik
            initialValues={user}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            enableReinitialize
          >
            {() => (
              <Form>
                <Row spacing={2} mt={{ md: 1, xs: 0 }} mb={6}>
                  <Col xs={12} md={12}>
                    <Stack direction={"row"} spacing={2} alignItems="center">
                      <Lock color="secondary" fontSize="large" />
                      <Typography
                        variant="h5"
                        fontWeight={700}
                        color={"secondary"}
                      >
                        Crie sua senha contendo 8-20 caracteres incluindo letras
                        e números.
                      </Typography>
                    </Stack>
                  </Col>

                  <Col xs={12} md={12}>
                    <Field
                      as={Input}
                      name="senha"
                      type="password"
                      placeholder="Senha*"
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>

                  <Col xs={12} md={12}>
                    <Field
                      as={Input}
                      name="confirmSenha"
                      type="password"
                      placeholder="Confirmar Senha Senha*"
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>

                  <Col xs={12} md={12} justifyContent="end">
                    <Button color="primary" type="submit" fullWidth>
                      Atualizar
                    </Button>{" "}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        }
      >
        <Col xs={12} container>
          <Col xs={12}>
            <Typography variant="h3" fontWeight={700} fontStyle={"italic"}>
              MEU CADASTRO
            </Typography>
            <Typography
              mt={"0.1rem"}
              fontStyle={"italic"}
              fontWeight={500}
              color="secondary"
            >
              Mantenha sempre atualizado os seus dados.
            </Typography>
          </Col>

          <Formik
            initialValues={user}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            enableReinitialize
          >
            {({ setFieldValue }) => (
              <Form>
                <Col xs={12} container spacing={1} mt={2}>
                  <Col xs={12} md={7}>
                    <Field
                      as={Input}
                      name="nome"
                      color="cinza"
                      placeholder="Nome completo"
                      fullWidth
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={5}>
                    <Field
                      as={InputMask}
                      mask={"999.999.999-99"}
                      name="cpf"
                      color="cinza"
                      placeholder="CPF"
                      fullWidth
                      size="large"
                    />
                  </Col>{" "}
                  <Col xs={12} md={4}>
                    <Field
                      as={InputMask}
                      mask="99/99/9999"
                      name="dataNascimento"
                      placeholder="Data de nascimento *"
                      fullWidth
                      size="large"
                      color="cinza"
                    />
                  </Col>{" "}
                  <Col xs={12} md={8}>
                    <Field
                      as={InputMask}
                      mask="(99) 99999-9999"
                      name="celular"
                      placeholder="Celular * "
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      as={Input}
                      name="email"
                      placeholder="E-mail *"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      as={InputMask}
                      name="cep"
                      mask={"99999-999"}
                      placeholder="CEP"
                      fullWidth
                      size="large"
                      color="cinza"
                      onBlur={onCep.bind(this, setFieldValue)}
                    />
                  </Col>
                  <Col xs={12} md={9}>
                    <Field
                      as={Input}
                      name="rua"
                      placeholder="Endereço*"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <Field
                      as={Input}
                      name="numero"
                      placeholder="Número*"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="bairro"
                      placeholder="Bairro*"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={InputMask}
                      name="complemento"
                      placeholder="Complemento"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="cidade"
                      placeholder="Cidade*"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="estado"
                      placeholder="Estado"
                      fullWidth
                      color="cinza"
                      size="large"
                    />
                  </Col>
                  <Col xs={12} mt={1}>
                    <Button color="primary" fullWidth type="submit">
                      Atualizar
                    </Button>
                  </Col>
                </Col>
              </Form>
            )}
          </Formik>
        </Col>

        <Col xs={12} container></Col>
      </MenuVisitante>
    </Container>
  );
};

const styles = {};

export default VisitantePerfil;
