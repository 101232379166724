import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { ToastContainer } from "react-toastify";
import { AuthProvider } from "react-auth-kit";

import routes from "./routes";

import theme from "./config/theme";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-toastify/dist/ReactToastify.css";

import "video-react/dist/video-react.css";

import './config/globalStyles.css';

const AppWrapper = () => useRoutes(routes);

function App() {
  return (
    <AuthProvider authType={"localstorage"} authName={"_auth"}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <CssBaseline />
        <Router>
          <AppWrapper />
        </Router>
      </ThemeProvider>{" "}
    </AuthProvider>
  );
}

export default App;
