export const API_URL = {
  PINTORES_CEP: { url: "/pintores", method: "GET" },
  VISITANTE: { url: "/visitante", method: "POST" },
  VISITANTE_UPDATE: { url: "/visitante", method: "PUT" },
  VISITANTE_AVALIACOES: { url: "/visitante/avaliacoes", method: "GET" },
  VISITANTE_AVALIACOES_UPDATE: { url: "/visitante/avaliacoes", method: "PUT" },
  PINTOR_AVALIACOES_CREATE: { url: "/visitante/avaliacoes", method: "POST" },
  PINTOR: { url: "/pintores", method: "POST" },
  PINTOR_ME: { url: "/pintores/me", method: "GET" },
  PINTOR_CERTIFICADO_DELETE: {
    url: "/pintores/certificados",
    method: "DELETE",
  },
  PINTOR_UPDATE: { url: "/pintores/update", method: "PUT" },
  PINTOR_GALERIA: { url: "/pintores/galeria", method: "POST" },
  PINTOR_GALERIA_DELETE: { url: "/pintores/galeria", method: "DELETE" },
  PINTOR_AVATAR: { url: "/pintores/avatar", method: "POST" },
  PINTOR_SW_PRO: { url: "/pintores/sw-pro", method: "POST" },
  PINTOR_PESQUISAS: { url: "/pintores/pesquisas", method: "GET" },
  PINTOR_AVALIACOES: { url: "/pintores/avaliacoes", method: "GET" },
  PINTOR_ORCAMENTO: { url: "/pintores/orcamento", method: "POST" },
  PINTOR_VIDEO: { url: "/pintores/assistiu-video", method: "POST" },
  PINTOR_VIDEOS: { url: "/pintores/videos-pedentes", method: "GET" },

  SERVICOS: { url: "/servicos", method: "GET" },
  CONTATO: { url: "/contato", method: "POST" },
  LOGIN: { url: "/auth/login", method: "POST" },
	LOGIN_SOCIAL: { url: "/auth/login-social", method: "POST" },
  RESET_SENHA: { url: "/auth/reset-senha", method: "POST" },
  NOVA_SENHA: { url: "/auth/reset-senha/nova-senha", method: "POST" },
};
