import { useState, useEffect } from "react";
import { API_URL } from "../config/apiUrl";
import { useApi } from "./useApi";

export const useUser = () => {
  const [{ data }, update] = useApi({ ...API_URL.PINTOR_ME }, { manual: true });
  const [user, setUser] = useState({});

  const updateMe = () => {
    update();
  };

  const saveUser = (newUser) => {
    localStorage.setItem("usuario", JSON.stringify(newUser));
    setUser(newUser);
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("usuario")) || {});
  }, []);

  useEffect(() => {
    if (data) {
      setUser(data);
      localStorage.setItem("usuario", JSON.stringify(data));
    }
  }, [data]);

  return { user, saveUser, updateMe };
};
