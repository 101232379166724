import { Navigate } from "react-router-dom";
import LayoutLadingPage from "./config/LayoutLadingPage";

import {
  CadastroPintor,
  CadastroVisitante,
  EncontreSeuCliente,
  EncontreSeuPintor,
  Home,
  PoliticaPrivacidade,
  TermoPintor,
  TermoVisitante,
} from "./pages";

import { AuthPintor, AuthVisitante } from "./config/auth";

const routes = [
  {
    path: "/",
    element: <LayoutLadingPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },

      {
        path: "cadastro-pintor",
        element: <CadastroPintor />,
      },
      {
        path: "visitante/:page",
        element: (
          <AuthVisitante>
            <EncontreSeuPintor />
          </AuthVisitante>
        ),
      },

      {
        path: "visitante/cadastro",
        element: <CadastroVisitante />,
      },

      {
        path: "pintor/:page",
        element: (
          <AuthPintor>
            <EncontreSeuCliente />
          </AuthPintor>
        ),
      },
      {
        path: "termo-contratante",
        element: <TermoVisitante />,
      },
      {
        path: "termo-pintor",
        element: <TermoPintor />,
      },
      {
        path: "politica-privacidade",
        element: <PoliticaPrivacidade />,
      },
    ],
  },

  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

export default routes;
