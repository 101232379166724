import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND_URL || "https://api.acheseupintor.com.br/",
});

api.interceptors.request.use(function (config) {
  config.headers["Authorization"] = config.headers["Authorization"]
    ? config.headers["Authorization"]
    : "Bearer " + localStorage.getItem("_auth");
  return config;
});

api.interceptors.response.use(
  function (response) {
    if (response?.data) toast.success(response?.data?.message);

    return response;
  },
  async function (error) {
    if (error.response?.data) {
      toast.error(error.response?.data?.message);
      return error.response?.data;
    } else {
      return { message: "Error no servidor." };
    }
  }
);

export default api;
