import React from "react";
import { Grid } from "@mui/material";

export const Row = React.forwardRef(
  ({ spacing = 1, sx, gap = "", ...props }, ref) => (
    <Grid
      ref={ref}
      spacing={spacing}
      container
      {...props}
      sx={{ flexGrow: 1, gap, ...sx }}
    />
  )
);
