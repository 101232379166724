import React from "react";
import Slider from "react-slick";

import {
  Container as ContainerMUI,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { Box, Stack } from "@mui/material";

export const Container = ({
  children,
  maxWidth = "md",
  loading,
  imgs = [],
  ...props
}) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <ContainerMUI disableGutters maxWidth={false}>
        <Stack
          sx={{
            width: "100%",
            minHeight: { xs: "auto", sm: "auto" },
          }}
        >
          <Slider {...settings}>
            {imgs.map((item) => (
              <Box>
                <img src={item} alt="Banner" style={{ width: "100%" }} />
              </Box>
            ))}
          </Slider>
        </Stack>
      </ContainerMUI>

      <ContainerMUI disableGutters maxWidth={maxWidth} {...props}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {children}
      </ContainerMUI>
    </>
  );
};
