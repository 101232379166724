import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSignIn, useSignOut } from "react-auth-kit";
import { isEmpty } from "lodash";
import axios from "axios";

import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { CheckBoxOutlined, Lock } from "@mui/icons-material";

import { useApi } from "../../hooks/useApi";
import { API_URL } from "../../config/apiUrl";

import {
  Container,
  Row,
  Col,
  Button,
  InputMask,
  Input,
  Radio,
  Select,
} from "../../components";
import api from "../../services/api";
import { useUser } from "../../hooks/useUser";
import banner from "../../assets/Banner_Cadastro_De_Pintor.png";
import { useRevedas } from "../../hooks/useRevendas";
import { toast } from "react-toastify";

const CadastroPintor = () => {
  const [loadingCep, setLoadingCep] = useState(false);
  const [image, setImage] = useState([]);
  // const [certificados, setCertificados] = useState([]);
  const [imgPerfil, setImgPerfil] = useState(null);
  const [acepted, setAcepted] = useState([]);
  const [categorias, setCategorias] = useState([]);

  const [isCpfValid, setIsCpfValid] = useState({
    error: false,
    isValid: false,
  });

  const navigate = useNavigate();
  const signIn = useSignIn();
  const signUp = useSignOut();

  const { saveUser } = useUser();

  const [{ data: servicos = [] }] = useApi({ ...API_URL.SERVICOS });
  const { data: revendas = [] } = useRevedas();

  const onChangeCategoria = (e) => {
    if (e.target.checked) {
      setCategorias([...categorias, Number(e.target.value)]);
    }
    if (!e.target.checked) {
      const i = categorias.indexOf(Number(e.target.value));

      let temp = categorias.splice(i, i === 0 ? 0 : 1);
      setCategorias([...temp]);
    }
  };

  const onGaleria = (e) => {
    if (e.target.files.length > 10) {
      toast.error("Vocẽ pode selecionar apenas 10 imagens");
      setImage([]);
      return;
    }
    setImage(e.target.files);
  };

  const onAcepted = (item) => {
    const newArr = acepted.filter((v) => v !== item);
    newArr.push(item);
    setAcepted(newArr);
  };

  const onPerfil = (e) => {
    setImgPerfil(e.target.files);
  };

  // const onCertificados = (e) => {
  //   setCertificados(e.target.files);
  // };

  const onValidCpf = async (e) => {
    setLoadingCep(true);
    const cpf = e.target.value;

    try {
      await axios.get(process.env.REACT_APP_SW_PRO_URL, {
        params: { cpf: cpf.replace(/[^0-9]/g, "") },
      });

      setIsCpfValid({
        error: false,
        isValid: true,
      });
    } catch (error) {
      setIsCpfValid({
        error: true,
        isValid: false,
      });
    }

    setLoadingCep(false);
  };

  const onSubmit = async (values) => {
    setLoadingCep(true);
    let isLogin = false;

    try {
      signUp();

      const { data: pintor } = await api({
        ...API_URL.PINTOR,
        data: { ...values, categorias },
      });

      signIn({
        token: pintor.access_token,
        expiresIn: pintor.expiresIn,
        authState: pintor.usuario,
        tokenType: "Bearer",
      });

      saveUser(pintor.usuario);

      isLogin = true;

      let formData = new FormData();

      for (const item of image) {
        formData.append("galeria", item);
      }

      const { data: galeria } = await api({
        ...API_URL.PINTOR_GALERIA,
        headers: { Authorization: `Bearer ${pintor.access_token}` },
        data: formData,
      });

      saveUser(galeria.usuario);

      formData = new FormData();
      formData.append("avatar", imgPerfil[0]);

      const { data: avatar } = await api({
        ...API_URL.PINTOR_AVATAR,
        headers: { Authorization: `Bearer ${pintor.access_token}` },
        data: formData,
      });

      saveUser(avatar.usuario);

      formData = new FormData();

      // for (const item of certificados) {
      //   formData.append("certificados", item);
      // }

      // const { data: swpro } = await api({
      //   ...API_URL.PINTOR_SW_PRO,
      //   headers: { Authorization: `Bearer ${pintor.access_token}` },
      //   data: formData,
      // });

      // saveUser(swpro.usuario);

      navigate("/pintor/galeria");
    } catch (error) {
      if (isLogin) {
        navigate("/pintor/galeria");
      }
    }

    setLoadingCep(false);
  };

  const onCep = async (setFieldValue, e) => {
    setLoadingCep(true);

    try {
      const cep = e.target.value.replace(/^\d{4}$/, "");
      const { data } = await api.get(`/cep?cep=${cep}`);

      setFieldValue("rua", data.endereco);
      setFieldValue("bairro", data.bairro);
      setFieldValue("cidade", data.cidade);
      setFieldValue("estado", data.uf);
    } catch (error) {}

    setLoadingCep(false);
  };

  return (
    <Container loading={loadingCep} imgs={[banner]}>
      <Row mt={6} mb={6} sx={styles.container}>
        <Col xs={12}>
          <Formik
            initialValues={{}}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <Row spacing={2}>
                  <Col xs={12} md={7}>
                    <Field
                      as={Input}
                      name="nome"
                      placeholder="Nome completo "
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={5}>
                    <Field
                      as={InputMask}
                      mask="999.999.999-99"
                      name="cpf"
                      placeholder="CPF"
                      fullWidth
                      required
                      error={isCpfValid.error}
                      helperText={
                        isCpfValid.error && (
                          <>
                            <Stack mb={1}>
                              Você precisa ser cadastrado no Clube Pro Pintor,
                              para fazer parte do Ache seu pintor.
                            </Stack>
                            <Button
                              color="primary"
                              size="small"
                              fullWidth
                              onClick={() => {
                                window.open(
                                  `https://clubepropintor.com.br/cadastrar`
                                );
                              }}
                            >
                              Cadastre-se
                            </Button>
                          </>
                        )
                      }
                      onBlur={onValidCpf}
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Field
                      as={InputMask}
                      mask="99/99/9999"
                      name="dataNascimento"
                      placeholder="Data de nascimento"
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      as={Input}
                      name="email"
                      placeholder="E-mail "
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={InputMask}
                      mask="(99) 99999-9999"
                      name="celular"
                      placeholder="Celular "
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="instagram"
                      placeholder="Instagram"
                      fullWidth
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      as={InputMask}
                      name="cep"
                      mask={"99999-999"}
                      placeholder="CEP"
                      fullWidth
                      size="large"
                      required
                      onBlur={onCep.bind(this, setFieldValue)}
                    />
                  </Col>
                  <Col xs={12} md={9}>
                    <Field
                      as={Input}
                      name="rua"
                      placeholder="Endereço"
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <Field
                      as={Input}
                      name="numero"
                      placeholder="Número"
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="bairro"
                      placeholder="Bairro"
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={InputMask}
                      name="complemento"
                      placeholder="Complemento"
                      fullWidth
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="cidade"
                      placeholder="Cidade"
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="estado"
                      placeholder="Estado"
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      as={Input}
                      name="sobre"
                      placeholder="Sobre"
                      multiline
                      rows={4}
                      fullWidth
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Stack direction={"row"} spacing={2} alignItems="center">
                      <Lock color="secondary" fontSize="large" />
                      <Typography
                        variant="h5"
                        fontWeight={700}
                        color={"secondary"}
                      >
                        Crie sua senha contendo 8-20 caracteres incluindo letras
                        e números.
                      </Typography>
                    </Stack>
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="senha"
                      type="password"
                      placeholder="Senha"
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      as={Input}
                      name="confirmSenha"
                      type="password"
                      placeholder="Confirmar  Senha"
                      fullWidth
                      required
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Stack direction={"row"} spacing={2} alignItems="center">
                      <CheckBoxOutlined color="secondary" fontSize="large" />
                      <Typography
                        variant="h5"
                        fontWeight={700}
                        color={"secondary"}
                      >
                        Em quais categorias de pintura você tem experiência e
                        expertise?
                      </Typography>
                    </Stack>

                    <Stack
                      mt={2}
                      sx={{
                        width: "100%",
                        background: "#fff",
                        p: "0.8rem",
                        borderRadius: "1rem",
                      }}
                    >
                      <Row
                        mt={2}
                        sx={{
                          width: "100%",
                          background: "#fff",
                          p: "0.8rem",
                          borderRadius: "1rem",
                        }}
                      >
                        {servicos.map((e) => (
                          <Col xs={12} sm={6} md={4} key={e.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={categorias?.includes(e.id)}
                                />
                              }
                              label={e.nome}
                              value={e.id}
                              onChange={onChangeCategoria}
                            />
                          </Col>
                        ))}
                      </Row>{" "}
                    </Stack>
                  </Col>
                  <Col xs={12} md={12}>
                    {/* <Stack
                      mt={2}
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent={"center"}
                      alignItems="center"
                      spacing={4}
                      sx={{
                        width: "100%",
                        background: "#fff",
                        p: "0.8rem",
                        borderRadius: "1rem",
                      }}
                    >
                      <Typography>
                        Anexe aqui seus certificados{" "}
                        <strong> SWPRO Cursos.</strong>
                      </Typography>

                      <Button color="primary" component="label">
                        {isEmpty(certificados)
                          ? "Selecione arquivos"
                          : `${certificados.length} arquivos selecionados`}
                        <input
                          type="file"
                          hidden
                          multiple
                          onChange={onCertificados}
                          required
                        />
                      </Button>
                    </Stack> */}
                    <Stack
                      mt={2}
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent={"center"}
                      alignItems="center"
                      spacing={4}
                      sx={{
                        width: "100%",
                        background: "#fff",
                        p: "0.8rem",
                        borderRadius: "1rem",
                      }}
                    >
                      <Typography>
                        Anexe aqui sua
                        <strong> Foto de perfil.</strong>
                      </Typography>

                      <Button color="primary" component="label">
                        {imgPerfil
                          ? `Imagem de perfil selecionada`
                          : "Selecione arquivos"}
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={onPerfil}
                          required
                        />
                      </Button>
                    </Stack>

                    <Stack
                      mt={2}
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent={"center"}
                      alignItems="center"
                      spacing={4}
                      sx={{
                        width: "100%",
                        background: "#fff",
                        p: "0.8rem",
                        borderRadius: "1rem",
                      }}
                    >
                      <Typography>
                        Anexe aqui sua
                        <strong> GALERIA.</strong>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          máximo 10 imagens
                        </span>
                      </Typography>

                      <Button color="primary" component="label">
                        {isEmpty(image)
                          ? "Selecione arquivos"
                          : `${image.length} arquivos selecionados`}
                        <input
                          type="file"
                          hidden
                          multiple
                          required
                          accept="image/*"
                          onChange={onGaleria}
                        />
                      </Button>
                    </Stack>
                  </Col>

                  <Col xs={12} md={12}>
                    <Field
                      as={Select}
                      name="lojaIndicada"
                      label="Você foi indicado por alguma loja Sherwin-Williams?"
                      menu={revendas}
                      fullWidth
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Radio
                      label={
                        "Aceito os termos de protocolo de privacidade e cookies."
                      }
                      alignItems="center"
                      onChange={onAcepted.bind(this, "1")}
                      color="secondary"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Radio
                      label={
                        "Quero receber mensagens e novidades por WhatsApp e e-mail."
                      }
                      alignItems="center"
                      onChange={onAcepted.bind(this, "2")}
                      color="secondary"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Radio
                      label={
                        " Concordo em compartilhar meus dados para uso exclusivo da Smart Tecnologia em vendas S.A, sendo direito da Smart transferência destas informações para uso da Sherwin-Williams e empresas afiliadas"
                      }
                      alignItems="center"
                      onChange={onAcepted.bind(this, "3")}
                      color="secondary"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Radio
                      label={
                        <>
                          Aceito os termos de uso do pintor. Que estão
                          disponíveis{" "}
                          <a href="/termo-pintor" target="_blank">
                            clicando aqui
                          </a>
                        </>
                      }
                      alignItems="center"
                      onChange={onAcepted.bind(this, "4")}
                      color="secondary"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Button
                      color="primary"
                      type="submit"
                      fullWidth
                      disabled={
                        isEmpty(image) ||
                        !imgPerfil ||
                        !(Number(acepted.length) === 4) ||
                        !isCpfValid?.isValid
                      }
                    >
                      Cadastrar
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  container: {
    background: "#f2f2f2",
    borderRadius: "22px",
    padding: "1rem",
  },
  box: {
    background: "#fff",
    borderRadius: "22px",
    padding: "1rem",
    minHeight: "100%",
  },
  boxPintores: {
    background: "#f2f2f2",
    borderRadius: "22px",
    padding: "1rem",
  },
  boxPintor: {
    background: "#fff",
    height: "215px",
    justifyContent: "center",
    padding: "0.6rem",
    borderRadius: "1rem",
    width: "100%",
  },
};

export default CadastroPintor;
