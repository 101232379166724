import React from 'react';
import './styles.css';
import { useGoogleLogin } from '@react-oauth/google';


const GoogleLogin = ({text, onLogin }) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
	    console.log(`useGoogleLogin`, tokenResponse);
	    onLogin(tokenResponse)
      // responseGoogle(tokenResponse);
    },
  });

  return (
    <button type={'button'} onClick={() => login()} className={'btn-primario btn-primario3'}>
	    <i className={'fa fa-google'}></i> {text || 'Login com Google'}
	</button>
  );
};

export default GoogleLogin;
