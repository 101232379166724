import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material/";
import { Close } from "@mui/icons-material";

export const Modal = ({
  open,
  onClose,
  title,
  children,
  loading,
  ...props
}) => {
  return (
    <Dialog open={open} onClose={onClose} {...props} fullWidth>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>
        <Stack width={"100%"} direction="row" justifyContent={"space-between"}>
          <Stack>{title}</Stack>
          <Stack>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
