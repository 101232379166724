import React from "react";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import "moment/locale/pt-br";
import { Stack, Typography } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

import { useApi } from "../../../hooks/useApi";
import { API_URL } from "../../../config/apiUrl";

import { Container, Row, Col, Button, InputMask } from "../../../components";
import MenuPintor from "./MenuPintor";

const Regiao = () => {
  moment.locale("pt-br");

  const [{ data = [], loading }, fetch] = useApi({
    ...API_URL.PINTOR_PESQUISAS,
  });

  const onSubmit = ({ cep }) => {
    fetch({ params: { cep } });
  };

  return (
    <Container loading={loading}>
      <MenuPintor>
        <Row>
          <Col xs={12}>
            <Typography
              variant="h2"
              fontStyle="italic"
              fontWeight={700}
              textAlign={"start"}
              mr={5}
            >
              MINHA REGIÂO
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              fontWeight={500}
              fontStyle="italic"
            >
              Digite seu CEP e confira as pesquisas próximas a você!{" "}
            </Typography>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                onSubmit(values);
              }}
            >
              {() => (
                <Form>
                  <Stack spacing={1} width="100%" direction={"row"} mt={3}>
                    <Field
                      as={InputMask}
                      mask="99999-999"
                      name="cep"
                      placeholder="CEP"
                      fullWidth
                      color="cinza"
                    />

                    <Button
                      color="primary"
                      sx={{ width: "200px" }}
                      type="submit"
                    >
                      <SearchOutlined />
                      BUSCAR
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>

            <Typography
              variant="body1"
              fontWeight={700}
              color="secondary"
              mt={4}
            >
              Total de {data.length} Pesquisas
            </Typography>

            <Typography variant="body2">
              As ultimas {data.length > 8 ? 8 : data.length} Pesquisas
            </Typography>

            {data.slice(0, 8).map((item, index) => (
              <Stack>
                <Stack
                  key={index}
                  sx={styles.boxPesquisa}
                  alignItems={"center"}
                  mt={2}
                  p={1}
                >
                  <Typography
                    variant="body1"
                    mt={1}
                    fontWeight={700}
                    fontSize={"14px"}
                  >
                    Data {moment(item.criadoEm).format("LLL")}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize={"14px"}
                    textAlign="center"
                  >
                    {item.cidade} - {item.estado}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Col>
        </Row>
      </MenuPintor>
    </Container>
  );
};

const styles = {
  boxPesquisa: {
    background: "#f2f2f2",
    marginLeft: "0.5rem",
    borderRadius: "15px",
  },
};

export default Regiao;
