import React from "react";
import { Stack, Button } from "@mui/material";

import { Modal } from "./Modal";
import { Image } from "./Avatar";

export const ModalMidia = ({ open, onClose, midia, onDelete }) => (
  <Modal open={open} onClose={onClose} title={"Midia"} maxWidth="xs">
    <Stack mt={2} justifyContent="center" alignItems="center">
      <Image
        src={midia.image || midia.documento}
        tipo={midia.tipo}
        style={{
          maxWidth: "100%",
          maxHeight: "110px",
          margin: "0 auto",
        }}
      />
    </Stack>
    <Stack mt={2}>
      <Button color="error" variant="outlined" onClick={onDelete}>
        EXCLUIR MIDIA
      </Button>
    </Stack>
  </Modal>
);
