import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { Stack, Typography, Button as ButtonMU } from "@mui/material";
import { Box } from "@mui/system";

import {
  Container,
  Row,
  Col,
  Button,
  Image,
  ModalMidia,
} from "../../../components";

import { useApi } from "../../../hooks/useApi";
import { useUser } from "../../../hooks/useUser";

import { API_URL } from "../../../config/apiUrl";

import MenuPintor from "./MenuPintor";
import pintoImage from "../../../assets/pintor-pintando-a-parede.png";
import { toast } from "react-toastify";

const Galeria = () => {
  const { user, saveUser, updateMe } = useUser();

  const [image, setImage] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = useState(false);

  const [{ loading, data }, fetch] = useApi(
    { ...API_URL.PINTOR_GALERIA },
    { manual: true }
  );

  const [{ loading: loadingDelete, data: dataDelete }, deleteRequest] = useApi(
    { ...API_URL.PINTOR_GALERIA_DELETE },
    { manual: true }
  );

  const onMidia = (item) => {
    setOpen(true);
    setSelectedImage(item);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    if (data) {
      saveUser(data.usuario);
    }
  }, [data]);

  useEffect(() => {
    if (dataDelete) {
      fetch();
      setSelectedImage(null);
      setOpen(false);
      updateMe();
    }
  }, [dataDelete]);

  const { galeria } = user;

  const onGaleria = (e) => {
    if (e.target.files.length > 10) {
      toast.error("Vocẽ pode selecionar apenas 10 imagens");
      setImage([]);
      return;
    }
    setImage(e.target.files);
  };

  const onDelete = () => {
    deleteRequest({
      data: { id: selectedImage.id, filename: selectedImage.image },
    });
  };

  const onSubmit = () => {
    const formData = new FormData();

    for (const item of image) {
      formData.append("galeria", item);
    }

    fetch({ data: formData });
    setImage([]);
  };

  return (
    <Container loading={loading || loadingDelete}>
      <MenuPintor>
        <Row
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={styles.box}
        >
          <Col xs={12}>
            <Row spacing={3}>
              <Col xs={12}>
                <Stack
                  justifyContent="center"
                  alignItem="center"
                  textAlign="center"
                  sx={{
                    height: "16rem",
                    border: "solid 1px ",
                    borderColor: "primary.main",
                    borderRadius: "1rem",
                    backgroundImage: `url(${pintoImage})`,
                    backgroundSize: "cover",
                  }}
                />
              </Col>
              <Col xs={12}>
                <Typography variant="h3" fontWeight={700} fontStyle={"italic"}>
                  Suas chances de trabalho acabaram de aumentar!{" "}
                </Typography>{" "}
                <Typography mt={"0.6rem"}>
                  Não se esqueça de atualizar seu perfil com novas fotos, videos
                  e avaliações de clientes sempre que possível - seus clientes
                  querem te conhecer!{" "}
                </Typography>{" "}
              </Col>
            </Row>
          </Col>
        </Row>
      </MenuPintor>

      <Col xs={12} mt={5}>
        <Row spacing={2}>
          <Col xs={12} md={12} sx={styles.box} spacing={2}>
            <Stack alignItems={"end"}>
              <Typography
                mt={1}
                variant="h5"
                fontStyle="italic"
                textAlign={"center"}
              >
                Para atrair mais clientes e divulgar seu trabalho, atualize
                sempre as fotos e vídeos do perfil.
                <br />
                <span style={{ fontSize: "12px", textAlign: "center" }}>
                  máximo 10 imagens
                </span>
              </Typography>
              <Box mt={2}>
                {isEmpty(image) && (
                  <Button color="primary" component="label">
                    Selecione arquivos
                    <input
                      type="file"
                      hidden
                      multiple
                      accept="image/*"
                      onChange={onGaleria}
                    />
                  </Button>
                )}
              </Box>
              {!isEmpty(image) && (
                <ButtonMU
                  color="success"
                  type="submit"
                  fullWidth
                  onClick={onSubmit}
                >
                  Enviar {image.length} imagens
                </ButtonMU>
              )}
            </Stack>
          </Col>

          {galeria?.map((item) => (
            <Col
              xs={11}
              md={4}
              key={item.image}
              onClick={onMidia.bind(this, item)}
            >
              <Stack p={0.5}>
                <Image
                  src={item.image}
                  tipo={item.tipo}
                  style={{
                    maxWidth: "90%",
                    maxHeight: "110px",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                />
              </Stack>
            </Col>
          ))}

          {isEmpty(galeria) && (
            <Col xs={12}>
              <Typography
                variant="h3"
                fontStyle="italic"
                fontWeight={700}
                textAlign={"center"}
                mr={5}
              >
                GALERIA <br /> VAZIA
              </Typography>
            </Col>
          )}
        </Row>
      </Col>
      {open && (
        <ModalMidia
          open={open}
          onClose={onClose}
          midia={selectedImage}
          onDelete={onDelete}
        />
      )}
    </Container>
  );
};

const styles = {
  box: {
    background: "#fff",
    borderRadius: "22px",
    padding: "1rem",
    minHeight: "100%",
  },

  boxCadastro: {
    background: "#f2f2f2",
    marginTop: "2rem",
    padding: "2rem",
    borderRadius: "30px",
  },
  boxImg: {
    width: "70px",
    height: "70px",
    border: "double 2px transparent",
    borderRadius: "100%",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
  },
};

export default Galeria;
