import React from "react";
import { Typography } from "@mui/material";
import { Player } from "video-react";

import { Row } from "./Row";
import { Col } from "./Col";

import { Modal } from "./Modal";

export const ModalTutorial = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} maxWidth="lg">
      <Row spacing={2}>
        <Col xs={12} md={12} container spacing={2}>
          <Col xs={12} md={7}>
            <Player
              playsInline
              poster="/assets/Banner_Home_4.png"
              src={`${process.env.REACT_APP_BACKEND_URL}/upload/consumidor.mp4`}
            />
          </Col>
          <Col xs={12} md={5}>
            <Typography variant="h1" color="secondary">
              Video Tutorial
            </Typography>
            <Typography variant="h2" color="primary" fontWeight={700}>
              Sou Cliente
            </Typography>
            <Typography>
              Ei, você está com dificuldades para achar o seu profissional ideal
              de pintura? A sherwin-Williams preparou um tutorial para te ajudar
              a navegar no site e achar o pintor ideal para a sua reforma,
              assista o vídeo ao lado e siga seus passos!
            </Typography>
          </Col>
        </Col>
        {/*<Col xs={12} md={12} container spacing={2} mt={10}>*/}
        {/*  <Col xs={12} md={5}>*/}
        {/*    <Typography variant="h1" color="secondary" textAlign={"end"}>*/}
        {/*      Video Tutorial*/}
        {/*    </Typography>*/}
        {/*    <Typography*/}
        {/*      variant="h2"*/}
        {/*      color="primary"*/}
        {/*      fontWeight={700}*/}
        {/*      textAlign={"end"}*/}
        {/*    >*/}
        {/*      Sou Pintor*/}
        {/*    </Typography>*/}
        {/*    <Typography textAlign={"end"}>*/}
        {/*      Amigo pintor, está querendo se cadastrar no Ache seu pintor, mas*/}
        {/*      está com dificuldades? Fique tranquilo a Sherwin-Williams preparou*/}
        {/*      um tutorial completo para facilitar a sua vida! Veja o vídeo ao*/}
        {/*      lado cadastre-se! E ache novos clientes!*/}
        {/*    </Typography>*/}
        {/*  </Col>*/}
        {/*  <Col xs={12} md={7}>*/}
        {/*    <Player*/}
        {/*      playsInline*/}
        {/*      poster="/assets/Banner_03_Home_Desktop.jpg"*/}
        {/*      src={`${process.env.REACT_APP_BACKEND_URL}/upload/pintor.mp4`}*/}
        {/*      style={{ borderRadius: "10px" }}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Col>{" "}*/}
      </Row>
    </Modal>
  );
};
