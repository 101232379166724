import { useEffect, useState } from "react";
import axios from "axios";

export const useRevedas = () => {
  const [data, setData] = useState([]);
  const fetch = async () => {
    try {
      const res = await axios.get(
        "https://www.clubepropintor.com.br/api/revendas"
      );

      const revendas = res.data?.revendas?.map((i) => ({
        value: i.nomeInput,
        label: i.nomeInput,
      }));

      setData(revendas);
    } catch (error) {
      console.log(error);

      setData([]);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return { data };
};
