import React from "react";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";

import { Container, Row } from "../../components";
import Historico from "./components/Historico";

import VisitantePintor from "./components/Pintor";
import VisitantePerfil from "./components/VisitantePerfil";
import banner from "../../assets/Banner_04_Home_Desktop.jpg";
import banner2 from "../../assets/Banner_historico_De_Contatos.jpg";

const EncontreSeuPintor = () => {
  const { page } = useParams();

  const onNavigate = () => {
    window.open("https://www.sherwin-williams.com.br/");
  };

  return (
    <Container imgs={[banner]}>
      <Row
        justifyContent="space-beteween"
        alignItem="center"
        sx={styles.boxCadastro}
        spacing={2}
        mb={6}
      >
        {page === "pintor" && <VisitantePintor />}
        {page === "perfil" && <VisitantePerfil />}
        {page === "historico" && <Historico />}
      </Row>
      <Stack sx={{ mb: 4, cursor: "pointer" }} onClick={onNavigate}>
        <img src={banner2} sx={{ maxWidth: "1200px" }} alt="" />
      </Stack>
    </Container>
  );
};

const styles = {
  box: {
    background: "#fff",
    borderRadius: "22px",
    padding: "1rem",
    minHeight: "100%",
  },
  boxPintor: {
    marginLeft: "10px",
    height: "220px",
    maxWidth: "150px",
    border: "double 2px transparent",
    borderRadius: "20px",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    cursor: "pointer",
  },
  boxCadastro: {
    background: "#f2f2f2",
    marginTop: "2rem",
    padding: "2rem",
    borderRadius: "30px",
  },
  boxImg: {
    width: "70px",
    height: "70px",
    border: "double 2px transparent",
    borderRadius: "100%",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
  },
  searchBox: {
    border: "double 3px transparent",
    borderRadius: "100%",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
  },
  avatar: {
    height: "90px",
    width: "90px",
    borderRadius: "100%",
    border: "solid 2px ",
    borderColor: "primary.main",
  },
};

export default EncontreSeuPintor;
