import React, { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { isEmpty } from "lodash";
import Slider from "react-slick";

import { Stack, Typography, Button as ButtonMU } from "@mui/material";
import { Box } from "@mui/system";

import { Container, Row, Col, Button, Image, Input } from "../../../components";

import { useApi } from "../../../hooks/useApi";
import { API_URL } from "../../../config/apiUrl";
import MenuPintor from "./MenuPintor";

const DuvidasFrequentes = () => {
  const [image, setImage] = useState([]);

  const [{ loading }, fetch] = useApi({
    ...API_URL.PINTOR_GALERIA,
  });

  const auth = useAuthUser();

  const onSubmit = () => {
    const formData = new FormData();

    for (const item of image) {
      formData.append("galeria", item);
    }

    fetch({ data: formData });
    setImage([]);
  };

  return (
    <Container loading={loading}>
      <MenuPintor>
        <Col xs={12}>
          <Typography
            variant="h2"
            fontStyle="italic"
            fontWeight={700}
            textAlign={"start"}
            mr={5}
          >
            DÚVIDAS <br /> FREQUENTES
          </Typography>
          <Typography
            variant="h5"
            color="secondary"
            fontWeight={700}
            fontStyle="italic"
            mt={4}
          >
            Pintou uma dúvida?
          </Typography>
          <Typography variant="body1" fontStyle="italic">
            <strong>Não se preocupe:</strong> estamos aqui para ajudar você!
          </Typography>
          <Typography
            variant="h5"
            color="secondary"
            fontWeight={700}
            fontStyle="italic"
            mt={2}
          >
            Como posso me tornar um pintor 5 estrelas?
          </Typography>
          <Typography variant="body1" fontStyle="italic">
            Para ter 5 estrelas, é necessario que seus clientes deixem pelo
            menos 10 comentários com a classificação "satisfeito"
          </Typography>
          <Typography
            variant="h5"
            color="secondary"
            fontWeight={700}
            fontStyle="italic"
            mt={2}
          >
            Porque um pintor receber 5 estrelas?{" "}
          </Typography>
          <Typography variant="body1" fontStyle="italic">
            Além de ser um exemplo para seus colegas e considerado para mais
            trabalhos, o pintor 5 estrelas ainda ganha 500 pontos no Clube PRO
            Pintor para trocar pelo que quiser.
          </Typography>{" "}
        </Col>
      </MenuPintor>

      <Col xs={12} mt={5}>
        <Row spacing={2}>
          <Col xs={12} md={5}>
            <Typography
              variant="h2"
              fontStyle="italic"
              fontWeight={700}
              textAlign={"start"}
              color="secondary"
              ml={5}
            >
              TIRE SUAS <br /> DÚVIDAS <br /> COM A <br /> SWPRO
            </Typography>
          </Col>
          <Col xs={12} md={7} container spacing={2}>
            <Col xs={12} md={6} color="#fff" fontWeight={700}>
              <Input name="nome" placeholder="Nome" />
            </Col>
            <Col xs={12} md={6} color="#fff" fontWeight={700}>
              <Input name="email" placeholder="Email" />
            </Col>
            <Col xs={12} color="#fff" fontWeight={700}>
              <Input
                name="comentario"
                placeholder="Comente sua dúvida"
                rows={4}
                multiline
              />
            </Col>
            <Col xs={12} color="#fff" fontWeight={700}>
              <Button fullWidth color="primary" onClick={onSubmit}>
                Enviar
              </Button>
            </Col>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

const styles = {
  box: {
    background: "#fff",
    borderRadius: "22px",
    padding: "1rem",
    minHeight: "100%",
  },

  boxCadastro: {
    background: "#f2f2f2",
    marginTop: "2rem",
    padding: "2rem",
    borderRadius: "30px",
  },
};

export default DuvidasFrequentes;
