import React from "react";
import { useNavigate } from "react-router-dom";
import { useSignOut } from "react-auth-kit";
import { Stack, Typography } from "@mui/material";
import {
  History,
  PersonSearch,
  SwitchAccount,
  WhatsApp,
  MailOutline,
} from "@mui/icons-material";

import { Row, Col, Button } from "../../../components";
import { useUser } from "../../../hooks/useUser";
import FacebookLogin from "react-facebook-login";
import { useApi } from "../../../hooks/useApi";
import { API_URL } from "../../../config/apiUrl";
import GoogleLogin from "../../../components/GoogleLogin";
import { GoogleOAuthProvider } from "@react-oauth/google";

const MenuVisitante = ({ children, subMenu }) => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const { saveUser, user } = useUser();

  const [{ data, loading }, fetch] = useApi(
    { ...API_URL.VISITANTE_UPDATE },
    { manual: true }
  );

  const onSingOut = () => {
    navigate("/");
    signOut();
    saveUser(null);
  };

  const onWhatsApp = () => {
    window.open("https://api.whatsapp.com/send?phone=5511911877987");
  };

  const responseFacebook = (e) => {
    console.log(`responseFacebook`, e);
    fetch({ data: { ...user, facebookId: e.id } });
    saveUser({ facebookId: e.id });
  };

  const removeFacebook = (e) => {
    console.log(`responseFacebook`, e);
    fetch({ data: { ...user, facebookId: null } });
    saveUser({ ...user, facebookId: null });
  };

  const responseGoogle = (e) => {
    fetch({ data: { ...user, googleOauth: e.access_token } });
    saveUser({ facebookId: e.access_token });
  };

  const removeGoogle = (e) => {
    console.log(`removeGoogle`, e);
    fetch({ data: { ...user, googleAuthId: null } });
    saveUser({ ...user, googleAuthId: null });
  };

  return (
    <Row
      justifyContent="space-beteween"
      alignItem="center"
      spacing={2}
      mb={2}
      mt={1}
    >
      <Col xs={12} md={5} sx={{ padding: "1rem" }}>
        <Row spacing={2} sx={{ ...styles.box }}>
          {[
            {
              title: "Pintores",
              subtitle: "Ache seu pintor",
              url: "/visitante/pintor",
              icon: <PersonSearch fontSize="large" color="secondary" />,
            },
            {
              title: "Meu Perfil",
              subtitle: "Confira as informações no seu perfil",
              url: "/visitante/perfil",
              icon: <SwitchAccount fontSize="large" color="secondary" />,
            },
            {
              title: "Histórico de contatos",
              subtitle: "Veja os pintores que você selecionou",
              url: "/visitante/historico",
              icon: <History fontSize="large" color="secondary" />,
            },
          ].map((item) => (
            <Col
              xs={12}
              sx={{ cursor: "pointer" }}
              onClick={navigate.bind(this, item.url)}
            >
              <Stack direction={"row"} fullWidth spacing={2}>
                <Stack>
                  <Stack
                    sx={styles.boxImg}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    {item.icon}
                  </Stack>
                </Stack>
                <Stack>
                  <Stack>
                    <Typography
                      variant="body1"
                      fontWeight={700}
                      fontStyle={"italic"}
                    >
                      {item.title}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontStyle={"italic"}
                    >
                      {item.subtitle}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Col>
          ))}
          <Col xs={12}>
            {/*callback={loginFacebook}*/}

            <div style={{ paddingTop: "8px" }}>
              {user.facebookId ? (
                <button
                  type={"button"}
                  onClick={removeFacebook}
                  className={"btn-primario btn-primario2"}
                >
                  <i className={"fa fa-facebook"}></i> Remover Login com
                  Facebook
                </button>
              ) : (
                <FacebookLogin
                  appId="571176977891327"
                  fields="name,email,picture"
                  icon="fa-facebook"
                  textButton="Sincronizar com Facebook"
                  cssClass="btn-primario btn-primario2"
                  callback={responseFacebook}
                />
              )}
            </div>

            <div style={{ paddingTop: "8px" }}>
              {user.googleAuthId ? (
                <button
                  type={"button"}
                  onClick={removeGoogle}
                  className={"btn-primario btn-primario3"}
                >
                  <i className={"fa fa-google"}></i> Remover Login com Google
                </button>
              ) : (
                <GoogleOAuthProvider clientId="425931863053-i3tf8n6mrujvl5slcs7pr4ql48po0eg8.apps.googleusercontent.com">
                  <GoogleLogin
                    text={"Sincronizar com Google"}
                    onLogin={responseGoogle}
                  ></GoogleLogin>
                </GoogleOAuthProvider>
              )}
            </div>
          </Col>

          <Col xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onSingOut}
            >
              Sair
            </Button>
          </Col>
          <Col xs={12}>
            <Stack
              justifyContent={"space-around"}
              alignItems="space-around"
              direction={"row"}
              spacing={1}
            >
              <Stack
                justifyContent={"center"}
                alignItems="center"
                spacing={0.7}
                onClick={onWhatsApp}
                sx={{ cursor: "pointer" }}
              >
                <WhatsApp fontSize="large" color="secondary" />
                <Typography variant="body1" fontSize={14} fontStyle={"italic"}>
                  WhatsApp{" "}
                </Typography>
              </Stack>{" "}
              <Stack
                justifyContent={"center"}
                alignItems="center"
                spacing={0.7}
                sx={{ cursor: "pointer" }}
              >
                <MailOutline fontSize="large" color="secondary" />
                <Typography variant="body1" fontSize={14} fontStyle={"italic"}>
                  Contato
                </Typography>
              </Stack>
            </Stack>
          </Col>
        </Row>

        {subMenu && (
          <Row spacing={2} sx={{ ...styles.box }} mt={2}>
            <Col xs={12}>{subMenu}</Col>
          </Row>
        )}
      </Col>
      <Col xs={12} md={7}>
        <Row
          spacing={2}
          sx={{ minHeight: subMenu ? "" : "100%", ...styles.box }}
        >
          {children}
        </Row>
      </Col>
    </Row>
  );
};

const styles = {
  box: {
    background: "#fff",
    borderRadius: "22px",
    padding: "1rem",
    justifyContent: "start",
    alignItem: "start",
  },

  boxCadastro: {
    background: "#f2f2f2",
    padding: "2rem",
    borderRadius: "30px",
  },
  boxImg: {
    width: "70px",
    height: "70px",
    border: "double 2px transparent",
    borderRadius: "100%",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(90deg, rgba(0,79,153,1) 25%, rgba(60,199,207,1) 100%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
  },
};

export default MenuVisitante;
