import React, { useEffect } from "react";

import { Typography } from "@mui/material";
import { Player } from "video-react";

import { Container, Row, Col } from "../../../components";

import { useApi } from "../../../hooks/useApi";
import { API_URL } from "../../../config/apiUrl";
import { useUser } from "../../../hooks/useUser";
import MenuPintor from "./MenuPintor";

const Certificados = () => {
  const { saveUser } = useUser();

  const [{ loading, data }, fetch] = useApi(
    { ...API_URL.PINTOR_VIDEO },
    { manual: true }
  );

  const [{ loading: loadingVideos, data: videos = [] }, refetch] = useApi({
    ...API_URL.PINTOR_VIDEOS,
  });

  useEffect(() => {
    if (data && data?.usuario?.status === "APROVADO") {
      saveUser(data?.usuario);
      window.open("/pintor/galeria", "_self");
    }

    if (data) {
      refetch();
    }
  }, [data]);

  const onSubmit = (video) => {
    const data = { video };
    fetch({ data });
  };

  return (
    <Container loading={loading || loadingVideos}>
      <MenuPintor>
        <Row spacing={2}>
          <Col xs={12}>
            <Typography
              variant="h7"
              sx={{ fontSize: "16px" }}
              color="error"
              fontWeight={500}
            >
              Assista todos os vídeos para confirmar suas especialidades
            </Typography>
          </Col>
          {videos?.map((item) => (
            <Col xs={12} md={12} container spacing={2} mt={1}>
              <Col xs={12} md={12}>
                <Player
                  playsInline
                  poster="https://www.acheseupintor.com.br/static/media/logo.c2f1485abe1add64934b.png"
                  onEnded={() => onSubmit(item?.videoNome)}
                  src={`${process.env.REACT_APP_BACKEND_URL}/upload/${item?.videoNome}.mp4`}
                />
              </Col>
              <Col xs={12} md={12}>
                <Typography variant="h2" color="primary" fontWeight={700}>
                  {item?.nome}
                </Typography>
              </Col>
            </Col>
          ))}
        </Row>
      </MenuPintor>
    </Container>
  );
};

export default Certificados;
