import React from "react";
import {
  TextField as InputBase,
  Radio as RadioMUI,
  FormControl,
  Stack,
  FormLabel,
  InputLabel,
  MenuItem,
  Select as SelectMUI,
  RadioGroup as RadioGroupMUI,
  FormControlLabel,
} from "@mui/material";
import MaskedInput from "react-input-mask";
import { Row } from "./Row";
import { Col } from "./Col";

const getStyle = (color) => {
  if (color === "default") {
    return {
      backgroundColor: "#fff",
      ".MuiInputBase-root": { color: "#000", fontWeight: 500 },
      "& label.Mui-focused": {
        color: "#000",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#dcdcdc",
        },
        "&:hover fieldset": {
          borderColor: "#dcdcdc",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#dcdcdc",
        },
      },
      "& fieldset": { borderColor: "#dcdcdc" },
    };
  }

  if (color === "cinza") {
    return {
      backgroundColor: "#F2F2F2",
      ".MuiInputBase-root": { color: "#000", fontWeight: 500 },
      "& label.Mui-focused": {
        color: "#000",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#F2F2F2",
        },
        "&:hover fieldset": {
          borderColor: "#F2F2F2",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#F2F2F2",
        },
      },
      "& fieldset": { borderColor: "#dcdcdc" },
      "& fieldset": {
        borderColor: "#F2F2F2",
      },
    };
  }
};

export const Input = React.forwardRef(
  (
    {
      size = "small",
      color = "default",
      fullWidth = true,
      label,
      placeholder,
      focused = true,
      ...props
    },
    ref
  ) => {
    const styles = getStyle(color);

    return (
      <InputBase
        inputRef={ref}
        variant="outlined"
        focused={focused}
        fullWidth={fullWidth}
        size={size}
        label={label || placeholder}
        sx={{
          borderRadius: "0.5rem",
          ...styles,
        }}
        {...props}
      />
    );
  }
);

export const InputMask = React.forwardRef(({ ...props }, ref) => {
  return (
    <MaskedInput ref={ref} {...props}>
      {(inputProps) => <Input {...inputProps} />}
    </MaskedInput>
  );
});

export const Radio = ({ label, alignItems = "start", ...props }) => {
  return (
    <FormControl>
      <Stack direction="row" alignItems={alignItems}>
        <RadioMUI {...props} />
        <FormLabel>{label}</FormLabel>
      </Stack>
    </FormControl>
  );
};

export const RadioGroup = React.forwardRef(
  ({ menus = [], value, ...props }, ref) => {
    return (
      <FormControl ref={ref}>
        <RadioGroupMUI defaultValue={value} value={value} {...props}>
          <Row>
            {menus.map((item) => (
              <Col xs={12} md={6}>
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio color="secondary" />}
                  label={item.label}
                />
              </Col>
            ))}
          </Row>
        </RadioGroupMUI>
      </FormControl>
    );
  }
);

export const Select = ({ sx, label, menu = [], color, ...props }) => {
  const styles = getStyle(color);

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel labelId="select-label">{label}</InputLabel>
      <SelectMUI
        labelId="select-label"
        label={label}
        {...props}
        sx={{
          borderRadius: "0.2rem",
          px: "0",
          py: "0",
          height: "56px",
          backgroundColor: "#fff",
          ...styles,

          ".MuiInputBase-root": { color: "#000", fontWeight: 500 },
          "& label.Mui-focused": {
            color: "#000",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#dcdcdc",
            },
            "&:hover fieldset": {
              borderColor: "#dcdcdc",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#dcdcdc",
            },
          },
          "& fieldset": { borderColor: "#dcdcdc" },
          ...sx,
        }}
      >
        {menu.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </SelectMUI>
    </FormControl>
  );
};
