import React from "react";
import Slider from "react-slick";
import { Outlet, useLocation } from "react-router-dom";

import { Box, Stack } from "@mui/material";

import { Header, Footer, Container } from "../components";

const LayoutLadingPage = () => {
  return (
    <Container
      maxWidth={false}
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      <Header />

      <Outlet />
      <Footer />
    </Container>
  );
};

export default LayoutLadingPage;
