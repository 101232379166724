import React from "react";
import { useParams } from "react-router-dom";

import { Container, Row } from "../../components";
import PintorPerfiel from "./components/PintorPerfiel";
import Galeria from "./components/Galeria";
import DuvidasFrequentes from "./components/DuvidasFrequentes";
import Regiao from "./components/Regiao";
import Avaliacoes from "./components/Avaliacoes";
import banner from "../../assets/Banner_03_Home_Desktop.jpg";
import banner2 from "../../assets/Banner_historico_De_Contatos.jpg";
import { Stack } from "@mui/material";
import Certificados from "./components/Certificados";
const EncontreSeuCliente = () => {
  const { page } = useParams();

  const onNavigate = () => {
    window.open("https://www.sherwin-williams.com.br/");
  };

  return (
    <Container imgs={[banner]}>
      <Row
        justifyContent="space-beteween"
        alignItem="center"
        sx={styles.boxCadastro}
        spacing={2}
        mb={6}
      >
        {page === "perfil" && <PintorPerfiel />}
        {page === "galeria" && <Galeria />}
        {page === "duvidas" && <DuvidasFrequentes />}
        {page === "regiao" && <Regiao />}
        {page === "avaliacoes" && <Avaliacoes />}
        {page === "certificados" && <Certificados />}
      </Row>
      <Stack sx={{ mb: 4, cursor: "pointer" }} onClick={onNavigate}>
        <img src={banner2} sx={{ maxWidth: "1200px" }} alt="" />
      </Stack>
    </Container>
  );
};

const styles = {
  boxCadastro: {
    background: "#f2f2f2",
    marginTop: "2rem",
    padding: "2rem",
    borderRadius: "30px",
  },
};

export default EncontreSeuCliente;
